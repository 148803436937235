// Generated from Expression.g4 by ANTLR 4.13.1
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {
	ATN,
	ATNDeserializer, DecisionState, DFA, FailedPredicateException,
	RecognitionException, NoViableAltException, BailErrorStrategy,
	Parser, ParserATNSimulator,
	RuleContext, ParserRuleContext, PredictionMode, PredictionContextCache,
	TerminalNode, RuleNode,
	Token, TokenStream,
	Interval, IntervalSet
} from 'antlr4';
import ExpressionVisitor from "./ExpressionVisitor.js";

// for running tests with parameters, TODO: discuss strategy for typed parameters in CI
// eslint-disable-next-line no-unused-vars
type int = number;

export default class ExpressionParser extends Parser {
	public static readonly NULL = 1;
	public static readonly TRUE = 2;
	public static readonly FALSE = 3;
	public static readonly GUID = 4;
	public static readonly AND = 5;
	public static readonly OR = 6;
	public static readonly NOT = 7;
	public static readonly GREATER_OR_EQUAL = 8;
	public static readonly LESS_OR_EQUAL = 9;
	public static readonly GREATER = 10;
	public static readonly LESS = 11;
	public static readonly EQUALS = 12;
	public static readonly NOT_EQUALS = 13;
	public static readonly OFTYPE = 14;
	public static readonly WHERE = 15;
	public static readonly ORDERBY = 16;
	public static readonly ORIGINALVALUE = 17;
	public static readonly COUNT = 18;
	public static readonly LENGTH = 19;
	public static readonly CONTAINS = 20;
	public static readonly STARTSWITH = 21;
	public static readonly ENDSWITH = 22;
	public static readonly INDEXOF = 23;
	public static readonly SUBSTRING = 24;
	public static readonly TRIMSECONDS = 25;
	public static readonly TRIMMILLISECONDS = 26;
	public static readonly FIRST = 27;
	public static readonly TAKE = 28;
	public static readonly SKIPQ = 29;
	public static readonly ANY = 30;
	public static readonly ALL = 31;
	public static readonly TOLOWER = 32;
	public static readonly TOUPPER = 33;
	public static readonly ISDEFAULT = 34;
	public static readonly URLENCODE = 35;
	public static readonly DATE = 36;
	public static readonly DATETIME = 37;
	public static readonly UTCDATETIME = 38;
	public static readonly ADDDAYS = 39;
	public static readonly SUM = 40;
	public static readonly ASC = 41;
	public static readonly DESC = 42;
	public static readonly WS = 43;
	public static readonly PATH_PART = 44;
	public static readonly DOT_SEPARATOR = 45;
	public static readonly SLASH_SEPARATOR = 46;
	public static readonly COMMA_SEPARATOR = 47;
	public static readonly PAREN_LEFT = 48;
	public static readonly PAREN_RIGHT = 49;
	public static readonly CURLY_BRACKET_LEFT = 50;
	public static readonly CURLY_BRACKET_RIGHT = 51;
	public static readonly OPEN_SQUARE_BRACKET = 52;
	public static readonly CLOSE_SQUARE_BRACKET = 53;
	public static readonly QUOTED_STRING = 54;
	public static readonly INT = 55;
	public static readonly FLOAT = 56;
	public static readonly MACRO = 57;
	public static readonly PARAM = 58;
	public static readonly EOF = Token.EOF;
	public static readonly RULE_dependency = 0;
	public static readonly RULE_dependency_path = 1;
	public static readonly RULE_dependency_expr = 2;
	public static readonly RULE_path = 3;
	public static readonly RULE_embedded_path = 4;
	public static readonly RULE_path_part = 5;
	public static readonly RULE_func_sequence = 6;
	public static readonly RULE_func = 7;
	public static readonly RULE_bool_result_func = 8;
	public static readonly RULE_where = 9;
	public static readonly RULE_any = 10;
	public static readonly RULE_all = 11;
	public static readonly RULE_orderby = 12;
	public static readonly RULE_orderby_core = 13;
	public static readonly RULE_oftype = 14;
	public static readonly RULE_originalvalue = 15;
	public static readonly RULE_count = 16;
	public static readonly RULE_length = 17;
	public static readonly RULE_contains = 18;
	public static readonly RULE_startswith = 19;
	public static readonly RULE_endswith = 20;
	public static readonly RULE_indexof = 21;
	public static readonly RULE_substring = 22;
	public static readonly RULE_trimseconds = 23;
	public static readonly RULE_trimmilliseconds = 24;
	public static readonly RULE_first = 25;
	public static readonly RULE_take = 26;
	public static readonly RULE_skipQ = 27;
	public static readonly RULE_tolower = 28;
	public static readonly RULE_toupper = 29;
	public static readonly RULE_isdefault = 30;
	public static readonly RULE_urlencode = 31;
	public static readonly RULE_date = 32;
	public static readonly RULE_datetime = 33;
	public static readonly RULE_utcdatetime = 34;
	public static readonly RULE_adddays = 35;
	public static readonly RULE_sum = 36;
	public static readonly RULE_int_func_argument = 37;
	public static readonly RULE_string_func_argument = 38;
	public static readonly RULE_type_name = 39;
	public static readonly RULE_expr = 40;
	public static readonly RULE_guid = 41;
	public static readonly RULE_expr_part = 42;
	public static readonly RULE_expr_part_no_operator = 43;
	public static readonly RULE_operator = 44;
	public static readonly RULE_separator = 45;
	public static readonly RULE_dot_separator = 46;
	public static readonly RULE_slash_separator = 47;
	public static readonly RULE_not = 48;
	public static readonly RULE_boolean_operator = 49;
	public static readonly literalNames: (string | null)[] = [ null, "'null'", 
                                                            "'true'", "'false'", 
                                                            "'Guid'", "'&&'", 
                                                            "'||'", "'!'", 
                                                            "'>='", "'<='", 
                                                            "'>'", "'<'", 
                                                            "'=='", "'!='", 
                                                            "'OfType'", 
                                                            "'Where'", "'OrderBy'", 
                                                            "'OriginalValue'", 
                                                            "'Count'", "'Length'", 
                                                            "'Contains'", 
                                                            "'StartsWith'", 
                                                            "'EndsWith'", 
                                                            "'IndexOf'", 
                                                            "'Substring'", 
                                                            "'TrimSeconds'", 
                                                            "'TrimMilliseconds'", 
                                                            "'First'", "'Take'", 
                                                            "'Skip'", "'Any'", 
                                                            "'All'", "'ToLower'", 
                                                            "'ToUpper'", 
                                                            "'IsDefault'", 
                                                            "'UrlEncode'", 
                                                            "'Date'", "'DateTime'", 
                                                            "'UtcDateTime'", 
                                                            "'AddDays'", 
                                                            "'Sum'", null, 
                                                            null, null, 
                                                            null, "'.'", 
                                                            "'/'", "','", 
                                                            "'('", "')'", 
                                                            "'{<'", "'>}'", 
                                                            "'[['", "']]'", 
                                                            null, null, 
                                                            null, "'%'" ];
	public static readonly symbolicNames: (string | null)[] = [ null, "NULL", 
                                                             "TRUE", "FALSE", 
                                                             "GUID", "AND", 
                                                             "OR", "NOT", 
                                                             "GREATER_OR_EQUAL", 
                                                             "LESS_OR_EQUAL", 
                                                             "GREATER", 
                                                             "LESS", "EQUALS", 
                                                             "NOT_EQUALS", 
                                                             "OFTYPE", "WHERE", 
                                                             "ORDERBY", 
                                                             "ORIGINALVALUE", 
                                                             "COUNT", "LENGTH", 
                                                             "CONTAINS", 
                                                             "STARTSWITH", 
                                                             "ENDSWITH", 
                                                             "INDEXOF", 
                                                             "SUBSTRING", 
                                                             "TRIMSECONDS", 
                                                             "TRIMMILLISECONDS", 
                                                             "FIRST", "TAKE", 
                                                             "SKIPQ", "ANY", 
                                                             "ALL", "TOLOWER", 
                                                             "TOUPPER", 
                                                             "ISDEFAULT", 
                                                             "URLENCODE", 
                                                             "DATE", "DATETIME", 
                                                             "UTCDATETIME", 
                                                             "ADDDAYS", 
                                                             "SUM", "ASC", 
                                                             "DESC", "WS", 
                                                             "PATH_PART", 
                                                             "DOT_SEPARATOR", 
                                                             "SLASH_SEPARATOR", 
                                                             "COMMA_SEPARATOR", 
                                                             "PAREN_LEFT", 
                                                             "PAREN_RIGHT", 
                                                             "CURLY_BRACKET_LEFT", 
                                                             "CURLY_BRACKET_RIGHT", 
                                                             "OPEN_SQUARE_BRACKET", 
                                                             "CLOSE_SQUARE_BRACKET", 
                                                             "QUOTED_STRING", 
                                                             "INT", "FLOAT", 
                                                             "MACRO", "PARAM" ];
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"dependency", "dependency_path", "dependency_expr", "path", "embedded_path", 
		"path_part", "func_sequence", "func", "bool_result_func", "where", "any", 
		"all", "orderby", "orderby_core", "oftype", "originalvalue", "count", 
		"length", "contains", "startswith", "endswith", "indexof", "substring", 
		"trimseconds", "trimmilliseconds", "first", "take", "skipQ", "tolower", 
		"toupper", "isdefault", "urlencode", "date", "datetime", "utcdatetime", 
		"adddays", "sum", "int_func_argument", "string_func_argument", "type_name", 
		"expr", "guid", "expr_part", "expr_part_no_operator", "operator", "separator", 
		"dot_separator", "slash_separator", "not", "boolean_operator",
	];
	public get grammarFileName(): string { return "Expression.g4"; }
	public get literalNames(): (string | null)[] { return ExpressionParser.literalNames; }
	public get symbolicNames(): (string | null)[] { return ExpressionParser.symbolicNames; }
	public get ruleNames(): string[] { return ExpressionParser.ruleNames; }
	public get serializedATN(): number[] { return ExpressionParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(this, ExpressionParser._ATN, ExpressionParser.DecisionsToDFA, new PredictionContextCache());
	}
	// @RuleVersion(0)
	public dependency(): DependencyContext {
		let localctx: DependencyContext = new DependencyContext(this, this._ctx, this.state);
		this.enterRule(localctx, 0, ExpressionParser.RULE_dependency);
		try {
			this.state = 105;
			this._errHandler.sync(this);
			switch ( this._interp.adaptivePredict(this._input, 0, this._ctx) ) {
			case 1:
				this.enterOuterAlt(localctx, 1);
				{
				this.state = 100;
				this.dot_separator();
				this.state = 101;
				this.match(ExpressionParser.EOF);
				}
				break;
			case 2:
				this.enterOuterAlt(localctx, 2);
				{
				this.state = 103;
				this.dependency_path();
				}
				break;
			case 3:
				this.enterOuterAlt(localctx, 3);
				{
				this.state = 104;
				this.dependency_expr();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public dependency_path(): Dependency_pathContext {
		let localctx: Dependency_pathContext = new Dependency_pathContext(this, this._ctx, this.state);
		this.enterRule(localctx, 2, ExpressionParser.RULE_dependency_path);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 107;
			this.path();
			this.state = 108;
			this.match(ExpressionParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public dependency_expr(): Dependency_exprContext {
		let localctx: Dependency_exprContext = new Dependency_exprContext(this, this._ctx, this.state);
		this.enterRule(localctx, 4, ExpressionParser.RULE_dependency_expr);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 110;
			this.expr();
			this.state = 111;
			this.match(ExpressionParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public path(): PathContext {
		let localctx: PathContext = new PathContext(this, this._ctx, this.state);
		this.enterRule(localctx, 6, ExpressionParser.RULE_path);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 115;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case 14:
			case 15:
			case 16:
			case 17:
			case 18:
			case 19:
			case 20:
			case 21:
			case 22:
			case 23:
			case 24:
			case 25:
			case 26:
			case 27:
			case 28:
			case 29:
			case 30:
			case 31:
			case 32:
			case 33:
			case 34:
			case 35:
			case 36:
			case 37:
			case 38:
			case 39:
			case 40:
				{
				this.state = 113;
				this.func_sequence();
				}
				break;
			case 44:
			case 57:
				{
				this.state = 114;
				this.path_part();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this.state = 122;
			this._errHandler.sync(this);
			_alt = this._interp.adaptivePredict(this._input, 2, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 117;
					this.separator();
					this.state = 118;
					this.path_part();
					}
					}
				}
				this.state = 124;
				this._errHandler.sync(this);
				_alt = this._interp.adaptivePredict(this._input, 2, this._ctx);
			}
			this.state = 126;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la===46) {
				{
				this.state = 125;
				this.slash_separator();
				}
			}

			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public embedded_path(): Embedded_pathContext {
		let localctx: Embedded_pathContext = new Embedded_pathContext(this, this._ctx, this.state);
		this.enterRule(localctx, 8, ExpressionParser.RULE_embedded_path);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 128;
			this.match(ExpressionParser.CURLY_BRACKET_LEFT);
			this.state = 129;
			this.path();
			this.state = 130;
			this.match(ExpressionParser.CURLY_BRACKET_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public path_part(): Path_partContext {
		let localctx: Path_partContext = new Path_partContext(this, this._ctx, this.state);
		this.enterRule(localctx, 10, ExpressionParser.RULE_path_part);
		let _la: number;
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 132;
			_la = this._input.LA(1);
			if(!(_la===44 || _la===57)) {
			this._errHandler.recoverInline(this);
			}
			else {
				this._errHandler.reportMatch(this);
			    this.consume();
			}
			this.state = 136;
			this._errHandler.sync(this);
			switch ( this._interp.adaptivePredict(this._input, 4, this._ctx) ) {
			case 1:
				{
				this.state = 133;
				this.dot_separator();
				this.state = 134;
				this.func_sequence();
				}
				break;
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public func_sequence(): Func_sequenceContext {
		let localctx: Func_sequenceContext = new Func_sequenceContext(this, this._ctx, this.state);
		this.enterRule(localctx, 12, ExpressionParser.RULE_func_sequence);
		try {
			let _alt: number;
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 138;
			this.func();
			this.state = 144;
			this._errHandler.sync(this);
			_alt = this._interp.adaptivePredict(this._input, 5, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					{
					{
					this.state = 139;
					this.dot_separator();
					this.state = 140;
					this.func();
					}
					}
				}
				this.state = 146;
				this._errHandler.sync(this);
				_alt = this._interp.adaptivePredict(this._input, 5, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public func(): FuncContext {
		let localctx: FuncContext = new FuncContext(this, this._ctx, this.state);
		this.enterRule(localctx, 14, ExpressionParser.RULE_func);
		try {
			this.state = 169;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case 15:
				this.enterOuterAlt(localctx, 1);
				{
				this.state = 147;
				this.where();
				}
				break;
			case 16:
				this.enterOuterAlt(localctx, 2);
				{
				this.state = 148;
				this.orderby();
				}
				break;
			case 14:
				this.enterOuterAlt(localctx, 3);
				{
				this.state = 149;
				this.oftype();
				}
				break;
			case 17:
				this.enterOuterAlt(localctx, 4);
				{
				this.state = 150;
				this.originalvalue();
				}
				break;
			case 18:
				this.enterOuterAlt(localctx, 5);
				{
				this.state = 151;
				this.count();
				}
				break;
			case 19:
				this.enterOuterAlt(localctx, 6);
				{
				this.state = 152;
				this.length();
				}
				break;
			case 23:
				this.enterOuterAlt(localctx, 7);
				{
				this.state = 153;
				this.indexof();
				}
				break;
			case 24:
				this.enterOuterAlt(localctx, 8);
				{
				this.state = 154;
				this.substring();
				}
				break;
			case 25:
				this.enterOuterAlt(localctx, 9);
				{
				this.state = 155;
				this.trimseconds();
				}
				break;
			case 26:
				this.enterOuterAlt(localctx, 10);
				{
				this.state = 156;
				this.trimmilliseconds();
				}
				break;
			case 27:
				this.enterOuterAlt(localctx, 11);
				{
				this.state = 157;
				this.first();
				}
				break;
			case 28:
				this.enterOuterAlt(localctx, 12);
				{
				this.state = 158;
				this.take();
				}
				break;
			case 29:
				this.enterOuterAlt(localctx, 13);
				{
				this.state = 159;
				this.skipQ();
				}
				break;
			case 32:
				this.enterOuterAlt(localctx, 14);
				{
				this.state = 160;
				this.tolower();
				}
				break;
			case 33:
				this.enterOuterAlt(localctx, 15);
				{
				this.state = 161;
				this.toupper();
				}
				break;
			case 20:
			case 21:
			case 22:
			case 30:
			case 31:
			case 34:
				this.enterOuterAlt(localctx, 16);
				{
				this.state = 162;
				this.bool_result_func();
				}
				break;
			case 35:
				this.enterOuterAlt(localctx, 17);
				{
				this.state = 163;
				this.urlencode();
				}
				break;
			case 36:
				this.enterOuterAlt(localctx, 18);
				{
				this.state = 164;
				this.date();
				}
				break;
			case 37:
				this.enterOuterAlt(localctx, 19);
				{
				this.state = 165;
				this.datetime();
				}
				break;
			case 38:
				this.enterOuterAlt(localctx, 20);
				{
				this.state = 166;
				this.utcdatetime();
				}
				break;
			case 39:
				this.enterOuterAlt(localctx, 21);
				{
				this.state = 167;
				this.adddays();
				}
				break;
			case 40:
				this.enterOuterAlt(localctx, 22);
				{
				this.state = 168;
				this.sum();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public bool_result_func(): Bool_result_funcContext {
		let localctx: Bool_result_funcContext = new Bool_result_funcContext(this, this._ctx, this.state);
		this.enterRule(localctx, 16, ExpressionParser.RULE_bool_result_func);
		try {
			this.state = 177;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case 20:
				this.enterOuterAlt(localctx, 1);
				{
				this.state = 171;
				this.contains();
				}
				break;
			case 21:
				this.enterOuterAlt(localctx, 2);
				{
				this.state = 172;
				this.startswith();
				}
				break;
			case 22:
				this.enterOuterAlt(localctx, 3);
				{
				this.state = 173;
				this.endswith();
				}
				break;
			case 30:
				this.enterOuterAlt(localctx, 4);
				{
				this.state = 174;
				this.any_();
				}
				break;
			case 31:
				this.enterOuterAlt(localctx, 5);
				{
				this.state = 175;
				this.all();
				}
				break;
			case 34:
				this.enterOuterAlt(localctx, 6);
				{
				this.state = 176;
				this.isdefault();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public where(): WhereContext {
		let localctx: WhereContext = new WhereContext(this, this._ctx, this.state);
		this.enterRule(localctx, 18, ExpressionParser.RULE_where);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 179;
			this.match(ExpressionParser.WHERE);
			this.state = 180;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 181;
			this.expr();
			this.state = 182;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public any_(): AnyContext {
		let localctx: AnyContext = new AnyContext(this, this._ctx, this.state);
		this.enterRule(localctx, 20, ExpressionParser.RULE_any);
		let _la: number;
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 184;
			this.match(ExpressionParser.ANY);
			this.state = 185;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 187;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if ((((_la) & ~0x1F) === 0 && ((1 << _la) & 4294951070) !== 0) || ((((_la - 32)) & ~0x1F) === 0 && ((1 << (_la - 32)) & 130355711) !== 0)) {
				{
				this.state = 186;
				this.expr();
				}
			}

			this.state = 189;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public all(): AllContext {
		let localctx: AllContext = new AllContext(this, this._ctx, this.state);
		this.enterRule(localctx, 22, ExpressionParser.RULE_all);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 191;
			this.match(ExpressionParser.ALL);
			this.state = 192;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 193;
			this.expr();
			this.state = 194;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public orderby(): OrderbyContext {
		let localctx: OrderbyContext = new OrderbyContext(this, this._ctx, this.state);
		this.enterRule(localctx, 24, ExpressionParser.RULE_orderby);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 196;
			this.match(ExpressionParser.ORDERBY);
			this.state = 197;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 198;
			this.orderby_core();
			this.state = 199;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public orderby_core(): Orderby_coreContext {
		let localctx: Orderby_coreContext = new Orderby_coreContext(this, this._ctx, this.state);
		this.enterRule(localctx, 26, ExpressionParser.RULE_orderby_core);
		let _la: number;
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 201;
			this.path();
			this.state = 203;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la===41 || _la===42) {
				{
				this.state = 202;
				_la = this._input.LA(1);
				if(!(_la===41 || _la===42)) {
				this._errHandler.recoverInline(this);
				}
				else {
					this._errHandler.reportMatch(this);
				    this.consume();
				}
				}
			}

			this.state = 212;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===47) {
				{
				{
				this.state = 205;
				this.match(ExpressionParser.COMMA_SEPARATOR);
				this.state = 206;
				this.path();
				this.state = 208;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la===41 || _la===42) {
					{
					this.state = 207;
					_la = this._input.LA(1);
					if(!(_la===41 || _la===42)) {
					this._errHandler.recoverInline(this);
					}
					else {
						this._errHandler.reportMatch(this);
					    this.consume();
					}
					}
				}

				}
				}
				this.state = 214;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public oftype(): OftypeContext {
		let localctx: OftypeContext = new OftypeContext(this, this._ctx, this.state);
		this.enterRule(localctx, 28, ExpressionParser.RULE_oftype);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 215;
			this.match(ExpressionParser.OFTYPE);
			this.state = 216;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 217;
			this.type_name();
			this.state = 218;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public originalvalue(): OriginalvalueContext {
		let localctx: OriginalvalueContext = new OriginalvalueContext(this, this._ctx, this.state);
		this.enterRule(localctx, 30, ExpressionParser.RULE_originalvalue);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 220;
			this.match(ExpressionParser.ORIGINALVALUE);
			this.state = 221;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 222;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public count(): CountContext {
		let localctx: CountContext = new CountContext(this, this._ctx, this.state);
		this.enterRule(localctx, 32, ExpressionParser.RULE_count);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 224;
			this.match(ExpressionParser.COUNT);
			this.state = 225;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 226;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public length(): LengthContext {
		let localctx: LengthContext = new LengthContext(this, this._ctx, this.state);
		this.enterRule(localctx, 34, ExpressionParser.RULE_length);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 228;
			this.match(ExpressionParser.LENGTH);
			this.state = 229;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 230;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public contains(): ContainsContext {
		let localctx: ContainsContext = new ContainsContext(this, this._ctx, this.state);
		this.enterRule(localctx, 36, ExpressionParser.RULE_contains);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 232;
			this.match(ExpressionParser.CONTAINS);
			this.state = 233;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 234;
			this.string_func_argument();
			this.state = 235;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public startswith(): StartswithContext {
		let localctx: StartswithContext = new StartswithContext(this, this._ctx, this.state);
		this.enterRule(localctx, 38, ExpressionParser.RULE_startswith);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 237;
			this.match(ExpressionParser.STARTSWITH);
			this.state = 238;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 239;
			this.string_func_argument();
			this.state = 240;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public endswith(): EndswithContext {
		let localctx: EndswithContext = new EndswithContext(this, this._ctx, this.state);
		this.enterRule(localctx, 40, ExpressionParser.RULE_endswith);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 242;
			this.match(ExpressionParser.ENDSWITH);
			this.state = 243;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 244;
			this.string_func_argument();
			this.state = 245;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public indexof(): IndexofContext {
		let localctx: IndexofContext = new IndexofContext(this, this._ctx, this.state);
		this.enterRule(localctx, 42, ExpressionParser.RULE_indexof);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 247;
			this.match(ExpressionParser.INDEXOF);
			this.state = 248;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 249;
			this.string_func_argument();
			this.state = 250;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public substring(): SubstringContext {
		let localctx: SubstringContext = new SubstringContext(this, this._ctx, this.state);
		this.enterRule(localctx, 44, ExpressionParser.RULE_substring);
		let _la: number;
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 252;
			this.match(ExpressionParser.SUBSTRING);
			this.state = 253;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 254;
			this.int_func_argument();
			this.state = 257;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la===47) {
				{
				this.state = 255;
				this.match(ExpressionParser.COMMA_SEPARATOR);
				this.state = 256;
				this.int_func_argument();
				}
			}

			this.state = 259;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public trimseconds(): TrimsecondsContext {
		let localctx: TrimsecondsContext = new TrimsecondsContext(this, this._ctx, this.state);
		this.enterRule(localctx, 46, ExpressionParser.RULE_trimseconds);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 261;
			this.match(ExpressionParser.TRIMSECONDS);
			this.state = 262;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 263;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public trimmilliseconds(): TrimmillisecondsContext {
		let localctx: TrimmillisecondsContext = new TrimmillisecondsContext(this, this._ctx, this.state);
		this.enterRule(localctx, 48, ExpressionParser.RULE_trimmilliseconds);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 265;
			this.match(ExpressionParser.TRIMMILLISECONDS);
			this.state = 266;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 267;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public first(): FirstContext {
		let localctx: FirstContext = new FirstContext(this, this._ctx, this.state);
		this.enterRule(localctx, 50, ExpressionParser.RULE_first);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 269;
			this.match(ExpressionParser.FIRST);
			this.state = 270;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 271;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public take(): TakeContext {
		let localctx: TakeContext = new TakeContext(this, this._ctx, this.state);
		this.enterRule(localctx, 52, ExpressionParser.RULE_take);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 273;
			this.match(ExpressionParser.TAKE);
			this.state = 274;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 275;
			this.int_func_argument();
			this.state = 276;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public skipQ(): SkipQContext {
		let localctx: SkipQContext = new SkipQContext(this, this._ctx, this.state);
		this.enterRule(localctx, 54, ExpressionParser.RULE_skipQ);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 278;
			this.match(ExpressionParser.SKIPQ);
			this.state = 279;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 280;
			this.int_func_argument();
			this.state = 281;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public tolower(): TolowerContext {
		let localctx: TolowerContext = new TolowerContext(this, this._ctx, this.state);
		this.enterRule(localctx, 56, ExpressionParser.RULE_tolower);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 283;
			this.match(ExpressionParser.TOLOWER);
			this.state = 284;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 285;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public toupper(): ToupperContext {
		let localctx: ToupperContext = new ToupperContext(this, this._ctx, this.state);
		this.enterRule(localctx, 58, ExpressionParser.RULE_toupper);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 287;
			this.match(ExpressionParser.TOUPPER);
			this.state = 288;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 289;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public isdefault(): IsdefaultContext {
		let localctx: IsdefaultContext = new IsdefaultContext(this, this._ctx, this.state);
		this.enterRule(localctx, 60, ExpressionParser.RULE_isdefault);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 291;
			this.match(ExpressionParser.ISDEFAULT);
			this.state = 292;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 293;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public urlencode(): UrlencodeContext {
		let localctx: UrlencodeContext = new UrlencodeContext(this, this._ctx, this.state);
		this.enterRule(localctx, 62, ExpressionParser.RULE_urlencode);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 295;
			this.match(ExpressionParser.URLENCODE);
			this.state = 296;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 297;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public date(): DateContext {
		let localctx: DateContext = new DateContext(this, this._ctx, this.state);
		this.enterRule(localctx, 64, ExpressionParser.RULE_date);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 299;
			this.match(ExpressionParser.DATE);
			this.state = 300;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 301;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public datetime(): DatetimeContext {
		let localctx: DatetimeContext = new DatetimeContext(this, this._ctx, this.state);
		this.enterRule(localctx, 66, ExpressionParser.RULE_datetime);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 303;
			this.match(ExpressionParser.DATETIME);
			this.state = 304;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 305;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public utcdatetime(): UtcdatetimeContext {
		let localctx: UtcdatetimeContext = new UtcdatetimeContext(this, this._ctx, this.state);
		this.enterRule(localctx, 68, ExpressionParser.RULE_utcdatetime);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 307;
			this.match(ExpressionParser.UTCDATETIME);
			this.state = 308;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 309;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public adddays(): AdddaysContext {
		let localctx: AdddaysContext = new AdddaysContext(this, this._ctx, this.state);
		this.enterRule(localctx, 70, ExpressionParser.RULE_adddays);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 311;
			this.match(ExpressionParser.ADDDAYS);
			this.state = 312;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 313;
			this.int_func_argument();
			this.state = 314;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public sum(): SumContext {
		let localctx: SumContext = new SumContext(this, this._ctx, this.state);
		this.enterRule(localctx, 72, ExpressionParser.RULE_sum);
		let _la: number;
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 316;
			this.match(ExpressionParser.SUM);
			this.state = 317;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 319;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if ((((_la) & ~0x1F) === 0 && ((1 << _la) & 4294950912) !== 0) || ((((_la - 32)) & ~0x1F) === 0 && ((1 << (_la - 32)) & 33559039) !== 0)) {
				{
				this.state = 318;
				this.path();
				}
			}

			this.state = 321;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public int_func_argument(): Int_func_argumentContext {
		let localctx: Int_func_argumentContext = new Int_func_argumentContext(this, this._ctx, this.state);
		this.enterRule(localctx, 74, ExpressionParser.RULE_int_func_argument);
		try {
			this.state = 327;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case 55:
				this.enterOuterAlt(localctx, 1);
				{
				this.state = 323;
				this.match(ExpressionParser.INT);
				}
				break;
			case 58:
				this.enterOuterAlt(localctx, 2);
				{
				this.state = 324;
				this.match(ExpressionParser.PARAM);
				}
				break;
			case 14:
			case 15:
			case 16:
			case 17:
			case 18:
			case 19:
			case 20:
			case 21:
			case 22:
			case 23:
			case 24:
			case 25:
			case 26:
			case 27:
			case 28:
			case 29:
			case 30:
			case 31:
			case 32:
			case 33:
			case 34:
			case 35:
			case 36:
			case 37:
			case 38:
			case 39:
			case 40:
			case 44:
			case 57:
				this.enterOuterAlt(localctx, 3);
				{
				this.state = 325;
				this.path();
				}
				break;
			case 50:
				this.enterOuterAlt(localctx, 4);
				{
				this.state = 326;
				this.embedded_path();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public string_func_argument(): String_func_argumentContext {
		let localctx: String_func_argumentContext = new String_func_argumentContext(this, this._ctx, this.state);
		this.enterRule(localctx, 76, ExpressionParser.RULE_string_func_argument);
		try {
			this.state = 333;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case 54:
				this.enterOuterAlt(localctx, 1);
				{
				this.state = 329;
				this.match(ExpressionParser.QUOTED_STRING);
				}
				break;
			case 58:
				this.enterOuterAlt(localctx, 2);
				{
				this.state = 330;
				this.match(ExpressionParser.PARAM);
				}
				break;
			case 14:
			case 15:
			case 16:
			case 17:
			case 18:
			case 19:
			case 20:
			case 21:
			case 22:
			case 23:
			case 24:
			case 25:
			case 26:
			case 27:
			case 28:
			case 29:
			case 30:
			case 31:
			case 32:
			case 33:
			case 34:
			case 35:
			case 36:
			case 37:
			case 38:
			case 39:
			case 40:
			case 44:
			case 57:
				this.enterOuterAlt(localctx, 3);
				{
				this.state = 331;
				this.path();
				}
				break;
			case 50:
				this.enterOuterAlt(localctx, 4);
				{
				this.state = 332;
				this.embedded_path();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public type_name(): Type_nameContext {
		let localctx: Type_nameContext = new Type_nameContext(this, this._ctx, this.state);
		this.enterRule(localctx, 78, ExpressionParser.RULE_type_name);
		let _la: number;
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 335;
			this.match(ExpressionParser.PATH_PART);
			this.state = 341;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===52) {
				{
				{
				this.state = 336;
				this.match(ExpressionParser.OPEN_SQUARE_BRACKET);
				this.state = 337;
				this.match(ExpressionParser.PATH_PART);
				this.state = 338;
				this.match(ExpressionParser.CLOSE_SQUARE_BRACKET);
				}
				}
				this.state = 343;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public expr(): ExprContext {
		let localctx: ExprContext = new ExprContext(this, this._ctx, this.state);
		this.enterRule(localctx, 80, ExpressionParser.RULE_expr);
		let _la: number;
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 344;
			this.expr_part();
			this.state = 350;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la===5 || _la===6) {
				{
				{
				this.state = 345;
				this.boolean_operator();
				this.state = 346;
				this.expr_part();
				}
				}
				this.state = 352;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public guid(): GuidContext {
		let localctx: GuidContext = new GuidContext(this, this._ctx, this.state);
		this.enterRule(localctx, 82, ExpressionParser.RULE_guid);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 353;
			this.match(ExpressionParser.GUID);
			this.state = 354;
			this.match(ExpressionParser.PAREN_LEFT);
			this.state = 355;
			this.match(ExpressionParser.QUOTED_STRING);
			this.state = 356;
			this.match(ExpressionParser.PAREN_RIGHT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public expr_part(): Expr_partContext {
		let localctx: Expr_partContext = new Expr_partContext(this, this._ctx, this.state);
		this.enterRule(localctx, 84, ExpressionParser.RULE_expr_part);
		let _la: number;
		try {
			this.state = 391;
			this._errHandler.sync(this);
			switch ( this._interp.adaptivePredict(this._input, 21, this._ctx) ) {
			case 1:
				this.enterOuterAlt(localctx, 1);
				{
				this.state = 368;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
				case 54:
					{
					this.state = 358;
					this.match(ExpressionParser.QUOTED_STRING);
					}
					break;
				case 1:
					{
					this.state = 359;
					this.match(ExpressionParser.NULL);
					}
					break;
				case 55:
					{
					this.state = 360;
					this.match(ExpressionParser.INT);
					}
					break;
				case 56:
					{
					this.state = 361;
					this.match(ExpressionParser.FLOAT);
					}
					break;
				case 2:
					{
					this.state = 362;
					this.match(ExpressionParser.TRUE);
					}
					break;
				case 3:
					{
					this.state = 363;
					this.match(ExpressionParser.FALSE);
					}
					break;
				case 58:
					{
					this.state = 364;
					this.match(ExpressionParser.PARAM);
					}
					break;
				case 4:
					{
					this.state = 365;
					this.guid();
					}
					break;
				case 14:
				case 15:
				case 16:
				case 17:
				case 18:
				case 19:
				case 20:
				case 21:
				case 22:
				case 23:
				case 24:
				case 25:
				case 26:
				case 27:
				case 28:
				case 29:
				case 30:
				case 31:
				case 32:
				case 33:
				case 34:
				case 35:
				case 36:
				case 37:
				case 38:
				case 39:
				case 40:
				case 44:
				case 57:
					{
					this.state = 366;
					this.path();
					}
					break;
				case 50:
					{
					this.state = 367;
					this.embedded_path();
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				this.state = 370;
				this.operator();
				this.state = 381;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
				case 54:
					{
					this.state = 371;
					this.match(ExpressionParser.QUOTED_STRING);
					}
					break;
				case 1:
					{
					this.state = 372;
					this.match(ExpressionParser.NULL);
					}
					break;
				case 55:
					{
					this.state = 373;
					this.match(ExpressionParser.INT);
					}
					break;
				case 56:
					{
					this.state = 374;
					this.match(ExpressionParser.FLOAT);
					}
					break;
				case 2:
					{
					this.state = 375;
					this.match(ExpressionParser.TRUE);
					}
					break;
				case 3:
					{
					this.state = 376;
					this.match(ExpressionParser.FALSE);
					}
					break;
				case 58:
					{
					this.state = 377;
					this.match(ExpressionParser.PARAM);
					}
					break;
				case 4:
					{
					this.state = 378;
					this.guid();
					}
					break;
				case 14:
				case 15:
				case 16:
				case 17:
				case 18:
				case 19:
				case 20:
				case 21:
				case 22:
				case 23:
				case 24:
				case 25:
				case 26:
				case 27:
				case 28:
				case 29:
				case 30:
				case 31:
				case 32:
				case 33:
				case 34:
				case 35:
				case 36:
				case 37:
				case 38:
				case 39:
				case 40:
				case 44:
				case 57:
					{
					this.state = 379;
					this.path();
					}
					break;
				case 50:
					{
					this.state = 380;
					this.embedded_path();
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				}
				break;
			case 2:
				this.enterOuterAlt(localctx, 2);
				{
				this.state = 384;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la===7) {
					{
					this.state = 383;
					this.not();
					}
				}

				this.state = 386;
				this.match(ExpressionParser.PAREN_LEFT);
				this.state = 387;
				this.expr();
				this.state = 388;
				this.match(ExpressionParser.PAREN_RIGHT);
				}
				break;
			case 3:
				this.enterOuterAlt(localctx, 3);
				{
				this.state = 390;
				this.expr_part_no_operator();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public expr_part_no_operator(): Expr_part_no_operatorContext {
		let localctx: Expr_part_no_operatorContext = new Expr_part_no_operatorContext(this, this._ctx, this.state);
		this.enterRule(localctx, 86, ExpressionParser.RULE_expr_part_no_operator);
		let _la: number;
		try {
			this.state = 403;
			this._errHandler.sync(this);
			switch ( this._interp.adaptivePredict(this._input, 24, this._ctx) ) {
			case 1:
				this.enterOuterAlt(localctx, 1);
				{
				this.state = 394;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la===7) {
					{
					this.state = 393;
					this.not();
					}
				}

				this.state = 396;
				this.path();
				}
				break;
			case 2:
				this.enterOuterAlt(localctx, 2);
				{
				this.state = 398;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la===7) {
					{
					this.state = 397;
					this.not();
					}
				}

				this.state = 400;
				this.embedded_path();
				}
				break;
			case 3:
				this.enterOuterAlt(localctx, 3);
				{
				this.state = 401;
				this.match(ExpressionParser.TRUE);
				}
				break;
			case 4:
				this.enterOuterAlt(localctx, 4);
				{
				this.state = 402;
				this.match(ExpressionParser.FALSE);
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public operator(): OperatorContext {
		let localctx: OperatorContext = new OperatorContext(this, this._ctx, this.state);
		this.enterRule(localctx, 88, ExpressionParser.RULE_operator);
		try {
			this.state = 411;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case 8:
				localctx = new GreaterOrEqualOperatorContext(this, localctx);
				this.enterOuterAlt(localctx, 1);
				{
				this.state = 405;
				this.match(ExpressionParser.GREATER_OR_EQUAL);
				}
				break;
			case 9:
				localctx = new LessOrEqualOperatorContext(this, localctx);
				this.enterOuterAlt(localctx, 2);
				{
				this.state = 406;
				this.match(ExpressionParser.LESS_OR_EQUAL);
				}
				break;
			case 10:
				localctx = new GreaterOperatorContext(this, localctx);
				this.enterOuterAlt(localctx, 3);
				{
				this.state = 407;
				this.match(ExpressionParser.GREATER);
				}
				break;
			case 11:
				localctx = new LessOperatorContext(this, localctx);
				this.enterOuterAlt(localctx, 4);
				{
				this.state = 408;
				this.match(ExpressionParser.LESS);
				}
				break;
			case 12:
				localctx = new EqualsOperatorContext(this, localctx);
				this.enterOuterAlt(localctx, 5);
				{
				this.state = 409;
				this.match(ExpressionParser.EQUALS);
				}
				break;
			case 13:
				localctx = new NotEqualsOperatorContext(this, localctx);
				this.enterOuterAlt(localctx, 6);
				{
				this.state = 410;
				this.match(ExpressionParser.NOT_EQUALS);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public separator(): SeparatorContext {
		let localctx: SeparatorContext = new SeparatorContext(this, this._ctx, this.state);
		this.enterRule(localctx, 90, ExpressionParser.RULE_separator);
		try {
			this.state = 415;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case 45:
				this.enterOuterAlt(localctx, 1);
				{
				this.state = 413;
				this.dot_separator();
				}
				break;
			case 46:
				this.enterOuterAlt(localctx, 2);
				{
				this.state = 414;
				this.slash_separator();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public dot_separator(): Dot_separatorContext {
		let localctx: Dot_separatorContext = new Dot_separatorContext(this, this._ctx, this.state);
		this.enterRule(localctx, 92, ExpressionParser.RULE_dot_separator);
		try {
			localctx = new DotSeparatorContext(this, localctx);
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 417;
			this.match(ExpressionParser.DOT_SEPARATOR);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public slash_separator(): Slash_separatorContext {
		let localctx: Slash_separatorContext = new Slash_separatorContext(this, this._ctx, this.state);
		this.enterRule(localctx, 94, ExpressionParser.RULE_slash_separator);
		try {
			localctx = new SlashSeparatorContext(this, localctx);
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 419;
			this.match(ExpressionParser.SLASH_SEPARATOR);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public not(): NotContext {
		let localctx: NotContext = new NotContext(this, this._ctx, this.state);
		this.enterRule(localctx, 96, ExpressionParser.RULE_not);
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 421;
			this.match(ExpressionParser.NOT);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}
	// @RuleVersion(0)
	public boolean_operator(): Boolean_operatorContext {
		let localctx: Boolean_operatorContext = new Boolean_operatorContext(this, this._ctx, this.state);
		this.enterRule(localctx, 98, ExpressionParser.RULE_boolean_operator);
		let _la: number;
		try {
			this.enterOuterAlt(localctx, 1);
			{
			this.state = 423;
			_la = this._input.LA(1);
			if(!(_la===5 || _la===6)) {
			this._errHandler.recoverInline(this);
			}
			else {
				this._errHandler.reportMatch(this);
			    this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return localctx;
	}

	public static readonly _serializedATN: number[] = [4,1,58,426,2,0,7,0,2,
	1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,
	10,7,10,2,11,7,11,2,12,7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,
	7,17,2,18,7,18,2,19,7,19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,
	24,2,25,7,25,2,26,7,26,2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,
	2,32,7,32,2,33,7,33,2,34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,
	39,7,39,2,40,7,40,2,41,7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,
	7,46,2,47,7,47,2,48,7,48,2,49,7,49,1,0,1,0,1,0,1,0,1,0,3,0,106,8,0,1,1,
	1,1,1,1,1,2,1,2,1,2,1,3,1,3,3,3,116,8,3,1,3,1,3,1,3,5,3,121,8,3,10,3,12,
	3,124,9,3,1,3,3,3,127,8,3,1,4,1,4,1,4,1,4,1,5,1,5,1,5,1,5,3,5,137,8,5,1,
	6,1,6,1,6,1,6,5,6,143,8,6,10,6,12,6,146,9,6,1,7,1,7,1,7,1,7,1,7,1,7,1,7,
	1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,1,7,3,7,170,8,7,
	1,8,1,8,1,8,1,8,1,8,1,8,3,8,178,8,8,1,9,1,9,1,9,1,9,1,9,1,10,1,10,1,10,
	3,10,188,8,10,1,10,1,10,1,11,1,11,1,11,1,11,1,11,1,12,1,12,1,12,1,12,1,
	12,1,13,1,13,3,13,204,8,13,1,13,1,13,1,13,3,13,209,8,13,5,13,211,8,13,10,
	13,12,13,214,9,13,1,14,1,14,1,14,1,14,1,14,1,15,1,15,1,15,1,15,1,16,1,16,
	1,16,1,16,1,17,1,17,1,17,1,17,1,18,1,18,1,18,1,18,1,18,1,19,1,19,1,19,1,
	19,1,19,1,20,1,20,1,20,1,20,1,20,1,21,1,21,1,21,1,21,1,21,1,22,1,22,1,22,
	1,22,1,22,3,22,258,8,22,1,22,1,22,1,23,1,23,1,23,1,23,1,24,1,24,1,24,1,
	24,1,25,1,25,1,25,1,25,1,26,1,26,1,26,1,26,1,26,1,27,1,27,1,27,1,27,1,27,
	1,28,1,28,1,28,1,28,1,29,1,29,1,29,1,29,1,30,1,30,1,30,1,30,1,31,1,31,1,
	31,1,31,1,32,1,32,1,32,1,32,1,33,1,33,1,33,1,33,1,34,1,34,1,34,1,34,1,35,
	1,35,1,35,1,35,1,35,1,36,1,36,1,36,3,36,320,8,36,1,36,1,36,1,37,1,37,1,
	37,1,37,3,37,328,8,37,1,38,1,38,1,38,1,38,3,38,334,8,38,1,39,1,39,1,39,
	1,39,5,39,340,8,39,10,39,12,39,343,9,39,1,40,1,40,1,40,1,40,5,40,349,8,
	40,10,40,12,40,352,9,40,1,41,1,41,1,41,1,41,1,41,1,42,1,42,1,42,1,42,1,
	42,1,42,1,42,1,42,1,42,1,42,3,42,369,8,42,1,42,1,42,1,42,1,42,1,42,1,42,
	1,42,1,42,1,42,1,42,1,42,3,42,382,8,42,1,42,3,42,385,8,42,1,42,1,42,1,42,
	1,42,1,42,3,42,392,8,42,1,43,3,43,395,8,43,1,43,1,43,3,43,399,8,43,1,43,
	1,43,1,43,3,43,404,8,43,1,44,1,44,1,44,1,44,1,44,1,44,3,44,412,8,44,1,45,
	1,45,3,45,416,8,45,1,46,1,46,1,47,1,47,1,48,1,48,1,49,1,49,1,49,0,0,50,
	0,2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,42,44,46,48,50,
	52,54,56,58,60,62,64,66,68,70,72,74,76,78,80,82,84,86,88,90,92,94,96,98,
	0,3,2,0,44,44,57,57,1,0,41,42,1,0,5,6,454,0,105,1,0,0,0,2,107,1,0,0,0,4,
	110,1,0,0,0,6,115,1,0,0,0,8,128,1,0,0,0,10,132,1,0,0,0,12,138,1,0,0,0,14,
	169,1,0,0,0,16,177,1,0,0,0,18,179,1,0,0,0,20,184,1,0,0,0,22,191,1,0,0,0,
	24,196,1,0,0,0,26,201,1,0,0,0,28,215,1,0,0,0,30,220,1,0,0,0,32,224,1,0,
	0,0,34,228,1,0,0,0,36,232,1,0,0,0,38,237,1,0,0,0,40,242,1,0,0,0,42,247,
	1,0,0,0,44,252,1,0,0,0,46,261,1,0,0,0,48,265,1,0,0,0,50,269,1,0,0,0,52,
	273,1,0,0,0,54,278,1,0,0,0,56,283,1,0,0,0,58,287,1,0,0,0,60,291,1,0,0,0,
	62,295,1,0,0,0,64,299,1,0,0,0,66,303,1,0,0,0,68,307,1,0,0,0,70,311,1,0,
	0,0,72,316,1,0,0,0,74,327,1,0,0,0,76,333,1,0,0,0,78,335,1,0,0,0,80,344,
	1,0,0,0,82,353,1,0,0,0,84,391,1,0,0,0,86,403,1,0,0,0,88,411,1,0,0,0,90,
	415,1,0,0,0,92,417,1,0,0,0,94,419,1,0,0,0,96,421,1,0,0,0,98,423,1,0,0,0,
	100,101,3,92,46,0,101,102,5,0,0,1,102,106,1,0,0,0,103,106,3,2,1,0,104,106,
	3,4,2,0,105,100,1,0,0,0,105,103,1,0,0,0,105,104,1,0,0,0,106,1,1,0,0,0,107,
	108,3,6,3,0,108,109,5,0,0,1,109,3,1,0,0,0,110,111,3,80,40,0,111,112,5,0,
	0,1,112,5,1,0,0,0,113,116,3,12,6,0,114,116,3,10,5,0,115,113,1,0,0,0,115,
	114,1,0,0,0,116,122,1,0,0,0,117,118,3,90,45,0,118,119,3,10,5,0,119,121,
	1,0,0,0,120,117,1,0,0,0,121,124,1,0,0,0,122,120,1,0,0,0,122,123,1,0,0,0,
	123,126,1,0,0,0,124,122,1,0,0,0,125,127,3,94,47,0,126,125,1,0,0,0,126,127,
	1,0,0,0,127,7,1,0,0,0,128,129,5,50,0,0,129,130,3,6,3,0,130,131,5,51,0,0,
	131,9,1,0,0,0,132,136,7,0,0,0,133,134,3,92,46,0,134,135,3,12,6,0,135,137,
	1,0,0,0,136,133,1,0,0,0,136,137,1,0,0,0,137,11,1,0,0,0,138,144,3,14,7,0,
	139,140,3,92,46,0,140,141,3,14,7,0,141,143,1,0,0,0,142,139,1,0,0,0,143,
	146,1,0,0,0,144,142,1,0,0,0,144,145,1,0,0,0,145,13,1,0,0,0,146,144,1,0,
	0,0,147,170,3,18,9,0,148,170,3,24,12,0,149,170,3,28,14,0,150,170,3,30,15,
	0,151,170,3,32,16,0,152,170,3,34,17,0,153,170,3,42,21,0,154,170,3,44,22,
	0,155,170,3,46,23,0,156,170,3,48,24,0,157,170,3,50,25,0,158,170,3,52,26,
	0,159,170,3,54,27,0,160,170,3,56,28,0,161,170,3,58,29,0,162,170,3,16,8,
	0,163,170,3,62,31,0,164,170,3,64,32,0,165,170,3,66,33,0,166,170,3,68,34,
	0,167,170,3,70,35,0,168,170,3,72,36,0,169,147,1,0,0,0,169,148,1,0,0,0,169,
	149,1,0,0,0,169,150,1,0,0,0,169,151,1,0,0,0,169,152,1,0,0,0,169,153,1,0,
	0,0,169,154,1,0,0,0,169,155,1,0,0,0,169,156,1,0,0,0,169,157,1,0,0,0,169,
	158,1,0,0,0,169,159,1,0,0,0,169,160,1,0,0,0,169,161,1,0,0,0,169,162,1,0,
	0,0,169,163,1,0,0,0,169,164,1,0,0,0,169,165,1,0,0,0,169,166,1,0,0,0,169,
	167,1,0,0,0,169,168,1,0,0,0,170,15,1,0,0,0,171,178,3,36,18,0,172,178,3,
	38,19,0,173,178,3,40,20,0,174,178,3,20,10,0,175,178,3,22,11,0,176,178,3,
	60,30,0,177,171,1,0,0,0,177,172,1,0,0,0,177,173,1,0,0,0,177,174,1,0,0,0,
	177,175,1,0,0,0,177,176,1,0,0,0,178,17,1,0,0,0,179,180,5,15,0,0,180,181,
	5,48,0,0,181,182,3,80,40,0,182,183,5,49,0,0,183,19,1,0,0,0,184,185,5,30,
	0,0,185,187,5,48,0,0,186,188,3,80,40,0,187,186,1,0,0,0,187,188,1,0,0,0,
	188,189,1,0,0,0,189,190,5,49,0,0,190,21,1,0,0,0,191,192,5,31,0,0,192,193,
	5,48,0,0,193,194,3,80,40,0,194,195,5,49,0,0,195,23,1,0,0,0,196,197,5,16,
	0,0,197,198,5,48,0,0,198,199,3,26,13,0,199,200,5,49,0,0,200,25,1,0,0,0,
	201,203,3,6,3,0,202,204,7,1,0,0,203,202,1,0,0,0,203,204,1,0,0,0,204,212,
	1,0,0,0,205,206,5,47,0,0,206,208,3,6,3,0,207,209,7,1,0,0,208,207,1,0,0,
	0,208,209,1,0,0,0,209,211,1,0,0,0,210,205,1,0,0,0,211,214,1,0,0,0,212,210,
	1,0,0,0,212,213,1,0,0,0,213,27,1,0,0,0,214,212,1,0,0,0,215,216,5,14,0,0,
	216,217,5,48,0,0,217,218,3,78,39,0,218,219,5,49,0,0,219,29,1,0,0,0,220,
	221,5,17,0,0,221,222,5,48,0,0,222,223,5,49,0,0,223,31,1,0,0,0,224,225,5,
	18,0,0,225,226,5,48,0,0,226,227,5,49,0,0,227,33,1,0,0,0,228,229,5,19,0,
	0,229,230,5,48,0,0,230,231,5,49,0,0,231,35,1,0,0,0,232,233,5,20,0,0,233,
	234,5,48,0,0,234,235,3,76,38,0,235,236,5,49,0,0,236,37,1,0,0,0,237,238,
	5,21,0,0,238,239,5,48,0,0,239,240,3,76,38,0,240,241,5,49,0,0,241,39,1,0,
	0,0,242,243,5,22,0,0,243,244,5,48,0,0,244,245,3,76,38,0,245,246,5,49,0,
	0,246,41,1,0,0,0,247,248,5,23,0,0,248,249,5,48,0,0,249,250,3,76,38,0,250,
	251,5,49,0,0,251,43,1,0,0,0,252,253,5,24,0,0,253,254,5,48,0,0,254,257,3,
	74,37,0,255,256,5,47,0,0,256,258,3,74,37,0,257,255,1,0,0,0,257,258,1,0,
	0,0,258,259,1,0,0,0,259,260,5,49,0,0,260,45,1,0,0,0,261,262,5,25,0,0,262,
	263,5,48,0,0,263,264,5,49,0,0,264,47,1,0,0,0,265,266,5,26,0,0,266,267,5,
	48,0,0,267,268,5,49,0,0,268,49,1,0,0,0,269,270,5,27,0,0,270,271,5,48,0,
	0,271,272,5,49,0,0,272,51,1,0,0,0,273,274,5,28,0,0,274,275,5,48,0,0,275,
	276,3,74,37,0,276,277,5,49,0,0,277,53,1,0,0,0,278,279,5,29,0,0,279,280,
	5,48,0,0,280,281,3,74,37,0,281,282,5,49,0,0,282,55,1,0,0,0,283,284,5,32,
	0,0,284,285,5,48,0,0,285,286,5,49,0,0,286,57,1,0,0,0,287,288,5,33,0,0,288,
	289,5,48,0,0,289,290,5,49,0,0,290,59,1,0,0,0,291,292,5,34,0,0,292,293,5,
	48,0,0,293,294,5,49,0,0,294,61,1,0,0,0,295,296,5,35,0,0,296,297,5,48,0,
	0,297,298,5,49,0,0,298,63,1,0,0,0,299,300,5,36,0,0,300,301,5,48,0,0,301,
	302,5,49,0,0,302,65,1,0,0,0,303,304,5,37,0,0,304,305,5,48,0,0,305,306,5,
	49,0,0,306,67,1,0,0,0,307,308,5,38,0,0,308,309,5,48,0,0,309,310,5,49,0,
	0,310,69,1,0,0,0,311,312,5,39,0,0,312,313,5,48,0,0,313,314,3,74,37,0,314,
	315,5,49,0,0,315,71,1,0,0,0,316,317,5,40,0,0,317,319,5,48,0,0,318,320,3,
	6,3,0,319,318,1,0,0,0,319,320,1,0,0,0,320,321,1,0,0,0,321,322,5,49,0,0,
	322,73,1,0,0,0,323,328,5,55,0,0,324,328,5,58,0,0,325,328,3,6,3,0,326,328,
	3,8,4,0,327,323,1,0,0,0,327,324,1,0,0,0,327,325,1,0,0,0,327,326,1,0,0,0,
	328,75,1,0,0,0,329,334,5,54,0,0,330,334,5,58,0,0,331,334,3,6,3,0,332,334,
	3,8,4,0,333,329,1,0,0,0,333,330,1,0,0,0,333,331,1,0,0,0,333,332,1,0,0,0,
	334,77,1,0,0,0,335,341,5,44,0,0,336,337,5,52,0,0,337,338,5,44,0,0,338,340,
	5,53,0,0,339,336,1,0,0,0,340,343,1,0,0,0,341,339,1,0,0,0,341,342,1,0,0,
	0,342,79,1,0,0,0,343,341,1,0,0,0,344,350,3,84,42,0,345,346,3,98,49,0,346,
	347,3,84,42,0,347,349,1,0,0,0,348,345,1,0,0,0,349,352,1,0,0,0,350,348,1,
	0,0,0,350,351,1,0,0,0,351,81,1,0,0,0,352,350,1,0,0,0,353,354,5,4,0,0,354,
	355,5,48,0,0,355,356,5,54,0,0,356,357,5,49,0,0,357,83,1,0,0,0,358,369,5,
	54,0,0,359,369,5,1,0,0,360,369,5,55,0,0,361,369,5,56,0,0,362,369,5,2,0,
	0,363,369,5,3,0,0,364,369,5,58,0,0,365,369,3,82,41,0,366,369,3,6,3,0,367,
	369,3,8,4,0,368,358,1,0,0,0,368,359,1,0,0,0,368,360,1,0,0,0,368,361,1,0,
	0,0,368,362,1,0,0,0,368,363,1,0,0,0,368,364,1,0,0,0,368,365,1,0,0,0,368,
	366,1,0,0,0,368,367,1,0,0,0,369,370,1,0,0,0,370,381,3,88,44,0,371,382,5,
	54,0,0,372,382,5,1,0,0,373,382,5,55,0,0,374,382,5,56,0,0,375,382,5,2,0,
	0,376,382,5,3,0,0,377,382,5,58,0,0,378,382,3,82,41,0,379,382,3,6,3,0,380,
	382,3,8,4,0,381,371,1,0,0,0,381,372,1,0,0,0,381,373,1,0,0,0,381,374,1,0,
	0,0,381,375,1,0,0,0,381,376,1,0,0,0,381,377,1,0,0,0,381,378,1,0,0,0,381,
	379,1,0,0,0,381,380,1,0,0,0,382,392,1,0,0,0,383,385,3,96,48,0,384,383,1,
	0,0,0,384,385,1,0,0,0,385,386,1,0,0,0,386,387,5,48,0,0,387,388,3,80,40,
	0,388,389,5,49,0,0,389,392,1,0,0,0,390,392,3,86,43,0,391,368,1,0,0,0,391,
	384,1,0,0,0,391,390,1,0,0,0,392,85,1,0,0,0,393,395,3,96,48,0,394,393,1,
	0,0,0,394,395,1,0,0,0,395,396,1,0,0,0,396,404,3,6,3,0,397,399,3,96,48,0,
	398,397,1,0,0,0,398,399,1,0,0,0,399,400,1,0,0,0,400,404,3,8,4,0,401,404,
	5,2,0,0,402,404,5,3,0,0,403,394,1,0,0,0,403,398,1,0,0,0,403,401,1,0,0,0,
	403,402,1,0,0,0,404,87,1,0,0,0,405,412,5,8,0,0,406,412,5,9,0,0,407,412,
	5,10,0,0,408,412,5,11,0,0,409,412,5,12,0,0,410,412,5,13,0,0,411,405,1,0,
	0,0,411,406,1,0,0,0,411,407,1,0,0,0,411,408,1,0,0,0,411,409,1,0,0,0,411,
	410,1,0,0,0,412,89,1,0,0,0,413,416,3,92,46,0,414,416,3,94,47,0,415,413,
	1,0,0,0,415,414,1,0,0,0,416,91,1,0,0,0,417,418,5,45,0,0,418,93,1,0,0,0,
	419,420,5,46,0,0,420,95,1,0,0,0,421,422,5,7,0,0,422,97,1,0,0,0,423,424,
	7,2,0,0,424,99,1,0,0,0,27,105,115,122,126,136,144,169,177,187,203,208,212,
	257,319,327,333,341,350,368,381,384,391,394,398,403,411,415];

	private static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!ExpressionParser.__ATN) {
			ExpressionParser.__ATN = new ATNDeserializer().deserialize(ExpressionParser._serializedATN);
		}

		return ExpressionParser.__ATN;
	}


	static DecisionsToDFA = ExpressionParser._ATN.decisionToState.map( (ds: DecisionState, index: number) => new DFA(ds, index) );

}

export class DependencyContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public dot_separator(): Dot_separatorContext {
		return this.getTypedRuleContext(Dot_separatorContext, 0) as Dot_separatorContext;
	}
	public EOF(): TerminalNode {
		return this.getToken(ExpressionParser.EOF, 0);
	}
	public dependency_path(): Dependency_pathContext {
		return this.getTypedRuleContext(Dependency_pathContext, 0) as Dependency_pathContext;
	}
	public dependency_expr(): Dependency_exprContext {
		return this.getTypedRuleContext(Dependency_exprContext, 0) as Dependency_exprContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_dependency;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitDependency) {
			return visitor.visitDependency(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Dependency_pathContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public path(): PathContext {
		return this.getTypedRuleContext(PathContext, 0) as PathContext;
	}
	public EOF(): TerminalNode {
		return this.getToken(ExpressionParser.EOF, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_dependency_path;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitDependency_path) {
			return visitor.visitDependency_path(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Dependency_exprContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public expr(): ExprContext {
		return this.getTypedRuleContext(ExprContext, 0) as ExprContext;
	}
	public EOF(): TerminalNode {
		return this.getToken(ExpressionParser.EOF, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_dependency_expr;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitDependency_expr) {
			return visitor.visitDependency_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class PathContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public func_sequence(): Func_sequenceContext {
		return this.getTypedRuleContext(Func_sequenceContext, 0) as Func_sequenceContext;
	}
	public path_part_list(): Path_partContext[] {
		return this.getTypedRuleContexts(Path_partContext) as Path_partContext[];
	}
	public path_part(i: number): Path_partContext {
		return this.getTypedRuleContext(Path_partContext, i) as Path_partContext;
	}
	public separator_list(): SeparatorContext[] {
		return this.getTypedRuleContexts(SeparatorContext) as SeparatorContext[];
	}
	public separator(i: number): SeparatorContext {
		return this.getTypedRuleContext(SeparatorContext, i) as SeparatorContext;
	}
	public slash_separator(): Slash_separatorContext {
		return this.getTypedRuleContext(Slash_separatorContext, 0) as Slash_separatorContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_path;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitPath) {
			return visitor.visitPath(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Embedded_pathContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public CURLY_BRACKET_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.CURLY_BRACKET_LEFT, 0);
	}
	public path(): PathContext {
		return this.getTypedRuleContext(PathContext, 0) as PathContext;
	}
	public CURLY_BRACKET_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.CURLY_BRACKET_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_embedded_path;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitEmbedded_path) {
			return visitor.visitEmbedded_path(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Path_partContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public PATH_PART(): TerminalNode {
		return this.getToken(ExpressionParser.PATH_PART, 0);
	}
	public MACRO(): TerminalNode {
		return this.getToken(ExpressionParser.MACRO, 0);
	}
	public dot_separator(): Dot_separatorContext {
		return this.getTypedRuleContext(Dot_separatorContext, 0) as Dot_separatorContext;
	}
	public func_sequence(): Func_sequenceContext {
		return this.getTypedRuleContext(Func_sequenceContext, 0) as Func_sequenceContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_path_part;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitPath_part) {
			return visitor.visitPath_part(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Func_sequenceContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public func_list(): FuncContext[] {
		return this.getTypedRuleContexts(FuncContext) as FuncContext[];
	}
	public func(i: number): FuncContext {
		return this.getTypedRuleContext(FuncContext, i) as FuncContext;
	}
	public dot_separator_list(): Dot_separatorContext[] {
		return this.getTypedRuleContexts(Dot_separatorContext) as Dot_separatorContext[];
	}
	public dot_separator(i: number): Dot_separatorContext {
		return this.getTypedRuleContext(Dot_separatorContext, i) as Dot_separatorContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_func_sequence;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitFunc_sequence) {
			return visitor.visitFunc_sequence(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class FuncContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public where(): WhereContext {
		return this.getTypedRuleContext(WhereContext, 0) as WhereContext;
	}
	public orderby(): OrderbyContext {
		return this.getTypedRuleContext(OrderbyContext, 0) as OrderbyContext;
	}
	public oftype(): OftypeContext {
		return this.getTypedRuleContext(OftypeContext, 0) as OftypeContext;
	}
	public originalvalue(): OriginalvalueContext {
		return this.getTypedRuleContext(OriginalvalueContext, 0) as OriginalvalueContext;
	}
	public count(): CountContext {
		return this.getTypedRuleContext(CountContext, 0) as CountContext;
	}
	public length(): LengthContext {
		return this.getTypedRuleContext(LengthContext, 0) as LengthContext;
	}
	public indexof(): IndexofContext {
		return this.getTypedRuleContext(IndexofContext, 0) as IndexofContext;
	}
	public substring(): SubstringContext {
		return this.getTypedRuleContext(SubstringContext, 0) as SubstringContext;
	}
	public trimseconds(): TrimsecondsContext {
		return this.getTypedRuleContext(TrimsecondsContext, 0) as TrimsecondsContext;
	}
	public trimmilliseconds(): TrimmillisecondsContext {
		return this.getTypedRuleContext(TrimmillisecondsContext, 0) as TrimmillisecondsContext;
	}
	public first(): FirstContext {
		return this.getTypedRuleContext(FirstContext, 0) as FirstContext;
	}
	public take(): TakeContext {
		return this.getTypedRuleContext(TakeContext, 0) as TakeContext;
	}
	public skipQ(): SkipQContext {
		return this.getTypedRuleContext(SkipQContext, 0) as SkipQContext;
	}
	public tolower(): TolowerContext {
		return this.getTypedRuleContext(TolowerContext, 0) as TolowerContext;
	}
	public toupper(): ToupperContext {
		return this.getTypedRuleContext(ToupperContext, 0) as ToupperContext;
	}
	public bool_result_func(): Bool_result_funcContext {
		return this.getTypedRuleContext(Bool_result_funcContext, 0) as Bool_result_funcContext;
	}
	public urlencode(): UrlencodeContext {
		return this.getTypedRuleContext(UrlencodeContext, 0) as UrlencodeContext;
	}
	public date(): DateContext {
		return this.getTypedRuleContext(DateContext, 0) as DateContext;
	}
	public datetime(): DatetimeContext {
		return this.getTypedRuleContext(DatetimeContext, 0) as DatetimeContext;
	}
	public utcdatetime(): UtcdatetimeContext {
		return this.getTypedRuleContext(UtcdatetimeContext, 0) as UtcdatetimeContext;
	}
	public adddays(): AdddaysContext {
		return this.getTypedRuleContext(AdddaysContext, 0) as AdddaysContext;
	}
	public sum(): SumContext {
		return this.getTypedRuleContext(SumContext, 0) as SumContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_func;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitFunc) {
			return visitor.visitFunc(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Bool_result_funcContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public contains(): ContainsContext {
		return this.getTypedRuleContext(ContainsContext, 0) as ContainsContext;
	}
	public startswith(): StartswithContext {
		return this.getTypedRuleContext(StartswithContext, 0) as StartswithContext;
	}
	public endswith(): EndswithContext {
		return this.getTypedRuleContext(EndswithContext, 0) as EndswithContext;
	}
	public any_(): AnyContext {
		return this.getTypedRuleContext(AnyContext, 0) as AnyContext;
	}
	public all(): AllContext {
		return this.getTypedRuleContext(AllContext, 0) as AllContext;
	}
	public isdefault(): IsdefaultContext {
		return this.getTypedRuleContext(IsdefaultContext, 0) as IsdefaultContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_bool_result_func;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitBool_result_func) {
			return visitor.visitBool_result_func(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class WhereContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public WHERE(): TerminalNode {
		return this.getToken(ExpressionParser.WHERE, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public expr(): ExprContext {
		return this.getTypedRuleContext(ExprContext, 0) as ExprContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_where;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitWhere) {
			return visitor.visitWhere(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class AnyContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public ANY(): TerminalNode {
		return this.getToken(ExpressionParser.ANY, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
	public expr(): ExprContext {
		return this.getTypedRuleContext(ExprContext, 0) as ExprContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_any;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitAny) {
			return visitor.visitAny(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class AllContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public ALL(): TerminalNode {
		return this.getToken(ExpressionParser.ALL, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public expr(): ExprContext {
		return this.getTypedRuleContext(ExprContext, 0) as ExprContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_all;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitAll) {
			return visitor.visitAll(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OrderbyContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public ORDERBY(): TerminalNode {
		return this.getToken(ExpressionParser.ORDERBY, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public orderby_core(): Orderby_coreContext {
		return this.getTypedRuleContext(Orderby_coreContext, 0) as Orderby_coreContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_orderby;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitOrderby) {
			return visitor.visitOrderby(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Orderby_coreContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public path_list(): PathContext[] {
		return this.getTypedRuleContexts(PathContext) as PathContext[];
	}
	public path(i: number): PathContext {
		return this.getTypedRuleContext(PathContext, i) as PathContext;
	}
	public COMMA_SEPARATOR_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.COMMA_SEPARATOR);
	}
	public COMMA_SEPARATOR(i: number): TerminalNode {
		return this.getToken(ExpressionParser.COMMA_SEPARATOR, i);
	}
	public ASC_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.ASC);
	}
	public ASC(i: number): TerminalNode {
		return this.getToken(ExpressionParser.ASC, i);
	}
	public DESC_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.DESC);
	}
	public DESC(i: number): TerminalNode {
		return this.getToken(ExpressionParser.DESC, i);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_orderby_core;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitOrderby_core) {
			return visitor.visitOrderby_core(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OftypeContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public OFTYPE(): TerminalNode {
		return this.getToken(ExpressionParser.OFTYPE, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public type_name(): Type_nameContext {
		return this.getTypedRuleContext(Type_nameContext, 0) as Type_nameContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_oftype;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitOftype) {
			return visitor.visitOftype(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OriginalvalueContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public ORIGINALVALUE(): TerminalNode {
		return this.getToken(ExpressionParser.ORIGINALVALUE, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_originalvalue;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitOriginalvalue) {
			return visitor.visitOriginalvalue(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class CountContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public COUNT(): TerminalNode {
		return this.getToken(ExpressionParser.COUNT, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_count;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitCount) {
			return visitor.visitCount(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class LengthContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public LENGTH(): TerminalNode {
		return this.getToken(ExpressionParser.LENGTH, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_length;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitLength) {
			return visitor.visitLength(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ContainsContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public CONTAINS(): TerminalNode {
		return this.getToken(ExpressionParser.CONTAINS, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public string_func_argument(): String_func_argumentContext {
		return this.getTypedRuleContext(String_func_argumentContext, 0) as String_func_argumentContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_contains;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitContains) {
			return visitor.visitContains(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class StartswithContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public STARTSWITH(): TerminalNode {
		return this.getToken(ExpressionParser.STARTSWITH, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public string_func_argument(): String_func_argumentContext {
		return this.getTypedRuleContext(String_func_argumentContext, 0) as String_func_argumentContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_startswith;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitStartswith) {
			return visitor.visitStartswith(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class EndswithContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public ENDSWITH(): TerminalNode {
		return this.getToken(ExpressionParser.ENDSWITH, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public string_func_argument(): String_func_argumentContext {
		return this.getTypedRuleContext(String_func_argumentContext, 0) as String_func_argumentContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_endswith;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitEndswith) {
			return visitor.visitEndswith(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class IndexofContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public INDEXOF(): TerminalNode {
		return this.getToken(ExpressionParser.INDEXOF, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public string_func_argument(): String_func_argumentContext {
		return this.getTypedRuleContext(String_func_argumentContext, 0) as String_func_argumentContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_indexof;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitIndexof) {
			return visitor.visitIndexof(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class SubstringContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public SUBSTRING(): TerminalNode {
		return this.getToken(ExpressionParser.SUBSTRING, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public int_func_argument_list(): Int_func_argumentContext[] {
		return this.getTypedRuleContexts(Int_func_argumentContext) as Int_func_argumentContext[];
	}
	public int_func_argument(i: number): Int_func_argumentContext {
		return this.getTypedRuleContext(Int_func_argumentContext, i) as Int_func_argumentContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
	public COMMA_SEPARATOR(): TerminalNode {
		return this.getToken(ExpressionParser.COMMA_SEPARATOR, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_substring;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitSubstring) {
			return visitor.visitSubstring(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class TrimsecondsContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public TRIMSECONDS(): TerminalNode {
		return this.getToken(ExpressionParser.TRIMSECONDS, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_trimseconds;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitTrimseconds) {
			return visitor.visitTrimseconds(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class TrimmillisecondsContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public TRIMMILLISECONDS(): TerminalNode {
		return this.getToken(ExpressionParser.TRIMMILLISECONDS, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_trimmilliseconds;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitTrimmilliseconds) {
			return visitor.visitTrimmilliseconds(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class FirstContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public FIRST(): TerminalNode {
		return this.getToken(ExpressionParser.FIRST, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_first;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitFirst) {
			return visitor.visitFirst(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class TakeContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public TAKE(): TerminalNode {
		return this.getToken(ExpressionParser.TAKE, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public int_func_argument(): Int_func_argumentContext {
		return this.getTypedRuleContext(Int_func_argumentContext, 0) as Int_func_argumentContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_take;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitTake) {
			return visitor.visitTake(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class SkipQContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public SKIPQ(): TerminalNode {
		return this.getToken(ExpressionParser.SKIPQ, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public int_func_argument(): Int_func_argumentContext {
		return this.getTypedRuleContext(Int_func_argumentContext, 0) as Int_func_argumentContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_skipQ;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitSkipQ) {
			return visitor.visitSkipQ(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class TolowerContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public TOLOWER(): TerminalNode {
		return this.getToken(ExpressionParser.TOLOWER, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_tolower;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitTolower) {
			return visitor.visitTolower(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ToupperContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public TOUPPER(): TerminalNode {
		return this.getToken(ExpressionParser.TOUPPER, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_toupper;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitToupper) {
			return visitor.visitToupper(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class IsdefaultContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public ISDEFAULT(): TerminalNode {
		return this.getToken(ExpressionParser.ISDEFAULT, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_isdefault;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitIsdefault) {
			return visitor.visitIsdefault(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class UrlencodeContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public URLENCODE(): TerminalNode {
		return this.getToken(ExpressionParser.URLENCODE, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_urlencode;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitUrlencode) {
			return visitor.visitUrlencode(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class DateContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public DATE(): TerminalNode {
		return this.getToken(ExpressionParser.DATE, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_date;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitDate) {
			return visitor.visitDate(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class DatetimeContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public DATETIME(): TerminalNode {
		return this.getToken(ExpressionParser.DATETIME, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_datetime;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitDatetime) {
			return visitor.visitDatetime(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class UtcdatetimeContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public UTCDATETIME(): TerminalNode {
		return this.getToken(ExpressionParser.UTCDATETIME, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_utcdatetime;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitUtcdatetime) {
			return visitor.visitUtcdatetime(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class AdddaysContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public ADDDAYS(): TerminalNode {
		return this.getToken(ExpressionParser.ADDDAYS, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public int_func_argument(): Int_func_argumentContext {
		return this.getTypedRuleContext(Int_func_argumentContext, 0) as Int_func_argumentContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_adddays;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitAdddays) {
			return visitor.visitAdddays(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class SumContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public SUM(): TerminalNode {
		return this.getToken(ExpressionParser.SUM, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
	public path(): PathContext {
		return this.getTypedRuleContext(PathContext, 0) as PathContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_sum;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitSum) {
			return visitor.visitSum(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Int_func_argumentContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public INT(): TerminalNode {
		return this.getToken(ExpressionParser.INT, 0);
	}
	public PARAM(): TerminalNode {
		return this.getToken(ExpressionParser.PARAM, 0);
	}
	public path(): PathContext {
		return this.getTypedRuleContext(PathContext, 0) as PathContext;
	}
	public embedded_path(): Embedded_pathContext {
		return this.getTypedRuleContext(Embedded_pathContext, 0) as Embedded_pathContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_int_func_argument;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitInt_func_argument) {
			return visitor.visitInt_func_argument(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class String_func_argumentContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public QUOTED_STRING(): TerminalNode {
		return this.getToken(ExpressionParser.QUOTED_STRING, 0);
	}
	public PARAM(): TerminalNode {
		return this.getToken(ExpressionParser.PARAM, 0);
	}
	public path(): PathContext {
		return this.getTypedRuleContext(PathContext, 0) as PathContext;
	}
	public embedded_path(): Embedded_pathContext {
		return this.getTypedRuleContext(Embedded_pathContext, 0) as Embedded_pathContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_string_func_argument;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitString_func_argument) {
			return visitor.visitString_func_argument(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Type_nameContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public PATH_PART_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.PATH_PART);
	}
	public PATH_PART(i: number): TerminalNode {
		return this.getToken(ExpressionParser.PATH_PART, i);
	}
	public OPEN_SQUARE_BRACKET_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.OPEN_SQUARE_BRACKET);
	}
	public OPEN_SQUARE_BRACKET(i: number): TerminalNode {
		return this.getToken(ExpressionParser.OPEN_SQUARE_BRACKET, i);
	}
	public CLOSE_SQUARE_BRACKET_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.CLOSE_SQUARE_BRACKET);
	}
	public CLOSE_SQUARE_BRACKET(i: number): TerminalNode {
		return this.getToken(ExpressionParser.CLOSE_SQUARE_BRACKET, i);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_type_name;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitType_name) {
			return visitor.visitType_name(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class ExprContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public expr_part_list(): Expr_partContext[] {
		return this.getTypedRuleContexts(Expr_partContext) as Expr_partContext[];
	}
	public expr_part(i: number): Expr_partContext {
		return this.getTypedRuleContext(Expr_partContext, i) as Expr_partContext;
	}
	public boolean_operator_list(): Boolean_operatorContext[] {
		return this.getTypedRuleContexts(Boolean_operatorContext) as Boolean_operatorContext[];
	}
	public boolean_operator(i: number): Boolean_operatorContext {
		return this.getTypedRuleContext(Boolean_operatorContext, i) as Boolean_operatorContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_expr;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitExpr) {
			return visitor.visitExpr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class GuidContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public GUID(): TerminalNode {
		return this.getToken(ExpressionParser.GUID, 0);
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public QUOTED_STRING(): TerminalNode {
		return this.getToken(ExpressionParser.QUOTED_STRING, 0);
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_guid;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitGuid) {
			return visitor.visitGuid(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Expr_partContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public operator(): OperatorContext {
		return this.getTypedRuleContext(OperatorContext, 0) as OperatorContext;
	}
	public QUOTED_STRING_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.QUOTED_STRING);
	}
	public QUOTED_STRING(i: number): TerminalNode {
		return this.getToken(ExpressionParser.QUOTED_STRING, i);
	}
	public NULL_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.NULL);
	}
	public NULL(i: number): TerminalNode {
		return this.getToken(ExpressionParser.NULL, i);
	}
	public INT_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.INT);
	}
	public INT(i: number): TerminalNode {
		return this.getToken(ExpressionParser.INT, i);
	}
	public FLOAT_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.FLOAT);
	}
	public FLOAT(i: number): TerminalNode {
		return this.getToken(ExpressionParser.FLOAT, i);
	}
	public TRUE_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.TRUE);
	}
	public TRUE(i: number): TerminalNode {
		return this.getToken(ExpressionParser.TRUE, i);
	}
	public FALSE_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.FALSE);
	}
	public FALSE(i: number): TerminalNode {
		return this.getToken(ExpressionParser.FALSE, i);
	}
	public PARAM_list(): TerminalNode[] {
	    	return this.getTokens(ExpressionParser.PARAM);
	}
	public PARAM(i: number): TerminalNode {
		return this.getToken(ExpressionParser.PARAM, i);
	}
	public guid_list(): GuidContext[] {
		return this.getTypedRuleContexts(GuidContext) as GuidContext[];
	}
	public guid(i: number): GuidContext {
		return this.getTypedRuleContext(GuidContext, i) as GuidContext;
	}
	public path_list(): PathContext[] {
		return this.getTypedRuleContexts(PathContext) as PathContext[];
	}
	public path(i: number): PathContext {
		return this.getTypedRuleContext(PathContext, i) as PathContext;
	}
	public embedded_path_list(): Embedded_pathContext[] {
		return this.getTypedRuleContexts(Embedded_pathContext) as Embedded_pathContext[];
	}
	public embedded_path(i: number): Embedded_pathContext {
		return this.getTypedRuleContext(Embedded_pathContext, i) as Embedded_pathContext;
	}
	public PAREN_LEFT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_LEFT, 0);
	}
	public expr(): ExprContext {
		return this.getTypedRuleContext(ExprContext, 0) as ExprContext;
	}
	public PAREN_RIGHT(): TerminalNode {
		return this.getToken(ExpressionParser.PAREN_RIGHT, 0);
	}
	public not(): NotContext {
		return this.getTypedRuleContext(NotContext, 0) as NotContext;
	}
	public expr_part_no_operator(): Expr_part_no_operatorContext {
		return this.getTypedRuleContext(Expr_part_no_operatorContext, 0) as Expr_part_no_operatorContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_expr_part;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitExpr_part) {
			return visitor.visitExpr_part(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Expr_part_no_operatorContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public path(): PathContext {
		return this.getTypedRuleContext(PathContext, 0) as PathContext;
	}
	public not(): NotContext {
		return this.getTypedRuleContext(NotContext, 0) as NotContext;
	}
	public embedded_path(): Embedded_pathContext {
		return this.getTypedRuleContext(Embedded_pathContext, 0) as Embedded_pathContext;
	}
	public TRUE(): TerminalNode {
		return this.getToken(ExpressionParser.TRUE, 0);
	}
	public FALSE(): TerminalNode {
		return this.getToken(ExpressionParser.FALSE, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_expr_part_no_operator;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitExpr_part_no_operator) {
			return visitor.visitExpr_part_no_operator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OperatorContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_operator;
	}
	public copyFrom(ctx: OperatorContext): void {
		super.copyFrom(ctx);
	}
}
export class LessOperatorContext extends OperatorContext {
	constructor(parser: ExpressionParser, ctx: OperatorContext) {
		super(parser, ctx.parentCtx, ctx.invokingState);
		super.copyFrom(ctx);
	}
	public LESS(): TerminalNode {
		return this.getToken(ExpressionParser.LESS, 0);
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitLessOperator) {
			return visitor.visitLessOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class GreaterOrEqualOperatorContext extends OperatorContext {
	constructor(parser: ExpressionParser, ctx: OperatorContext) {
		super(parser, ctx.parentCtx, ctx.invokingState);
		super.copyFrom(ctx);
	}
	public GREATER_OR_EQUAL(): TerminalNode {
		return this.getToken(ExpressionParser.GREATER_OR_EQUAL, 0);
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitGreaterOrEqualOperator) {
			return visitor.visitGreaterOrEqualOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class LessOrEqualOperatorContext extends OperatorContext {
	constructor(parser: ExpressionParser, ctx: OperatorContext) {
		super(parser, ctx.parentCtx, ctx.invokingState);
		super.copyFrom(ctx);
	}
	public LESS_OR_EQUAL(): TerminalNode {
		return this.getToken(ExpressionParser.LESS_OR_EQUAL, 0);
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitLessOrEqualOperator) {
			return visitor.visitLessOrEqualOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class NotEqualsOperatorContext extends OperatorContext {
	constructor(parser: ExpressionParser, ctx: OperatorContext) {
		super(parser, ctx.parentCtx, ctx.invokingState);
		super.copyFrom(ctx);
	}
	public NOT_EQUALS(): TerminalNode {
		return this.getToken(ExpressionParser.NOT_EQUALS, 0);
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitNotEqualsOperator) {
			return visitor.visitNotEqualsOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class GreaterOperatorContext extends OperatorContext {
	constructor(parser: ExpressionParser, ctx: OperatorContext) {
		super(parser, ctx.parentCtx, ctx.invokingState);
		super.copyFrom(ctx);
	}
	public GREATER(): TerminalNode {
		return this.getToken(ExpressionParser.GREATER, 0);
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitGreaterOperator) {
			return visitor.visitGreaterOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class EqualsOperatorContext extends OperatorContext {
	constructor(parser: ExpressionParser, ctx: OperatorContext) {
		super(parser, ctx.parentCtx, ctx.invokingState);
		super.copyFrom(ctx);
	}
	public EQUALS(): TerminalNode {
		return this.getToken(ExpressionParser.EQUALS, 0);
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitEqualsOperator) {
			return visitor.visitEqualsOperator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class SeparatorContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public dot_separator(): Dot_separatorContext {
		return this.getTypedRuleContext(Dot_separatorContext, 0) as Dot_separatorContext;
	}
	public slash_separator(): Slash_separatorContext {
		return this.getTypedRuleContext(Slash_separatorContext, 0) as Slash_separatorContext;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_separator;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitSeparator) {
			return visitor.visitSeparator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Dot_separatorContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_dot_separator;
	}
	public copyFrom(ctx: Dot_separatorContext): void {
		super.copyFrom(ctx);
	}
}
export class DotSeparatorContext extends Dot_separatorContext {
	constructor(parser: ExpressionParser, ctx: Dot_separatorContext) {
		super(parser, ctx.parentCtx, ctx.invokingState);
		super.copyFrom(ctx);
	}
	public DOT_SEPARATOR(): TerminalNode {
		return this.getToken(ExpressionParser.DOT_SEPARATOR, 0);
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitDotSeparator) {
			return visitor.visitDotSeparator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Slash_separatorContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_slash_separator;
	}
	public copyFrom(ctx: Slash_separatorContext): void {
		super.copyFrom(ctx);
	}
}
export class SlashSeparatorContext extends Slash_separatorContext {
	constructor(parser: ExpressionParser, ctx: Slash_separatorContext) {
		super(parser, ctx.parentCtx, ctx.invokingState);
		super.copyFrom(ctx);
	}
	public SLASH_SEPARATOR(): TerminalNode {
		return this.getToken(ExpressionParser.SLASH_SEPARATOR, 0);
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitSlashSeparator) {
			return visitor.visitSlashSeparator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class NotContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public NOT(): TerminalNode {
		return this.getToken(ExpressionParser.NOT, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_not;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitNot) {
			return visitor.visitNot(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Boolean_operatorContext extends ParserRuleContext {
	constructor(parser?: ExpressionParser, parent?: ParserRuleContext, invokingState?: number) {
		super(parent, invokingState);
    	this.parser = parser;
	}
	public AND(): TerminalNode {
		return this.getToken(ExpressionParser.AND, 0);
	}
	public OR(): TerminalNode {
		return this.getToken(ExpressionParser.OR, 0);
	}
    public get ruleIndex(): number {
    	return ExpressionParser.RULE_boolean_operator;
	}
	// @Override
	public accept<Result>(visitor: ExpressionVisitor<Result>): Result {
		if (visitor.visitBoolean_operator) {
			return visitor.visitBoolean_operator(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
