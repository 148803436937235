// Generated from Expression.g4 by ANTLR 4.13.1
// noinspection ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols
import {
	ATN,
	ATNDeserializer,
	CharStream,
	DecisionState, DFA,
	Lexer,
	LexerATNSimulator,
	RuleContext,
	PredictionContextCache,
	Token
} from "antlr4";
export default class ExpressionLexer extends Lexer {
	public static readonly NULL = 1;
	public static readonly TRUE = 2;
	public static readonly FALSE = 3;
	public static readonly GUID = 4;
	public static readonly AND = 5;
	public static readonly OR = 6;
	public static readonly NOT = 7;
	public static readonly GREATER_OR_EQUAL = 8;
	public static readonly LESS_OR_EQUAL = 9;
	public static readonly GREATER = 10;
	public static readonly LESS = 11;
	public static readonly EQUALS = 12;
	public static readonly NOT_EQUALS = 13;
	public static readonly OFTYPE = 14;
	public static readonly WHERE = 15;
	public static readonly ORDERBY = 16;
	public static readonly ORIGINALVALUE = 17;
	public static readonly COUNT = 18;
	public static readonly LENGTH = 19;
	public static readonly CONTAINS = 20;
	public static readonly STARTSWITH = 21;
	public static readonly ENDSWITH = 22;
	public static readonly INDEXOF = 23;
	public static readonly SUBSTRING = 24;
	public static readonly TRIMSECONDS = 25;
	public static readonly TRIMMILLISECONDS = 26;
	public static readonly FIRST = 27;
	public static readonly TAKE = 28;
	public static readonly SKIPQ = 29;
	public static readonly ANY = 30;
	public static readonly ALL = 31;
	public static readonly TOLOWER = 32;
	public static readonly TOUPPER = 33;
	public static readonly ISDEFAULT = 34;
	public static readonly URLENCODE = 35;
	public static readonly DATE = 36;
	public static readonly DATETIME = 37;
	public static readonly UTCDATETIME = 38;
	public static readonly ADDDAYS = 39;
	public static readonly SUM = 40;
	public static readonly ASC = 41;
	public static readonly DESC = 42;
	public static readonly WS = 43;
	public static readonly PATH_PART = 44;
	public static readonly DOT_SEPARATOR = 45;
	public static readonly SLASH_SEPARATOR = 46;
	public static readonly COMMA_SEPARATOR = 47;
	public static readonly PAREN_LEFT = 48;
	public static readonly PAREN_RIGHT = 49;
	public static readonly CURLY_BRACKET_LEFT = 50;
	public static readonly CURLY_BRACKET_RIGHT = 51;
	public static readonly OPEN_SQUARE_BRACKET = 52;
	public static readonly CLOSE_SQUARE_BRACKET = 53;
	public static readonly QUOTED_STRING = 54;
	public static readonly INT = 55;
	public static readonly FLOAT = 56;
	public static readonly MACRO = 57;
	public static readonly PARAM = 58;
	public static readonly EOF = Token.EOF;

	public static readonly channelNames: string[] = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	public static readonly literalNames: (string | null)[] = [ null, "'null'", 
                                                            "'true'", "'false'", 
                                                            "'Guid'", "'&&'", 
                                                            "'||'", "'!'", 
                                                            "'>='", "'<='", 
                                                            "'>'", "'<'", 
                                                            "'=='", "'!='", 
                                                            "'OfType'", 
                                                            "'Where'", "'OrderBy'", 
                                                            "'OriginalValue'", 
                                                            "'Count'", "'Length'", 
                                                            "'Contains'", 
                                                            "'StartsWith'", 
                                                            "'EndsWith'", 
                                                            "'IndexOf'", 
                                                            "'Substring'", 
                                                            "'TrimSeconds'", 
                                                            "'TrimMilliseconds'", 
                                                            "'First'", "'Take'", 
                                                            "'Skip'", "'Any'", 
                                                            "'All'", "'ToLower'", 
                                                            "'ToUpper'", 
                                                            "'IsDefault'", 
                                                            "'UrlEncode'", 
                                                            "'Date'", "'DateTime'", 
                                                            "'UtcDateTime'", 
                                                            "'AddDays'", 
                                                            "'Sum'", null, 
                                                            null, null, 
                                                            null, "'.'", 
                                                            "'/'", "','", 
                                                            "'('", "')'", 
                                                            "'{<'", "'>}'", 
                                                            "'[['", "']]'", 
                                                            null, null, 
                                                            null, "'%'" ];
	public static readonly symbolicNames: (string | null)[] = [ null, "NULL", 
                                                             "TRUE", "FALSE", 
                                                             "GUID", "AND", 
                                                             "OR", "NOT", 
                                                             "GREATER_OR_EQUAL", 
                                                             "LESS_OR_EQUAL", 
                                                             "GREATER", 
                                                             "LESS", "EQUALS", 
                                                             "NOT_EQUALS", 
                                                             "OFTYPE", "WHERE", 
                                                             "ORDERBY", 
                                                             "ORIGINALVALUE", 
                                                             "COUNT", "LENGTH", 
                                                             "CONTAINS", 
                                                             "STARTSWITH", 
                                                             "ENDSWITH", 
                                                             "INDEXOF", 
                                                             "SUBSTRING", 
                                                             "TRIMSECONDS", 
                                                             "TRIMMILLISECONDS", 
                                                             "FIRST", "TAKE", 
                                                             "SKIPQ", "ANY", 
                                                             "ALL", "TOLOWER", 
                                                             "TOUPPER", 
                                                             "ISDEFAULT", 
                                                             "URLENCODE", 
                                                             "DATE", "DATETIME", 
                                                             "UTCDATETIME", 
                                                             "ADDDAYS", 
                                                             "SUM", "ASC", 
                                                             "DESC", "WS", 
                                                             "PATH_PART", 
                                                             "DOT_SEPARATOR", 
                                                             "SLASH_SEPARATOR", 
                                                             "COMMA_SEPARATOR", 
                                                             "PAREN_LEFT", 
                                                             "PAREN_RIGHT", 
                                                             "CURLY_BRACKET_LEFT", 
                                                             "CURLY_BRACKET_RIGHT", 
                                                             "OPEN_SQUARE_BRACKET", 
                                                             "CLOSE_SQUARE_BRACKET", 
                                                             "QUOTED_STRING", 
                                                             "INT", "FLOAT", 
                                                             "MACRO", "PARAM" ];
	public static readonly modeNames: string[] = [ "DEFAULT_MODE", ];

	public static readonly ruleNames: string[] = [
		"NULL", "TRUE", "FALSE", "GUID", "AND", "OR", "NOT", "GREATER_OR_EQUAL", 
		"LESS_OR_EQUAL", "GREATER", "LESS", "EQUALS", "NOT_EQUALS", "OFTYPE", 
		"WHERE", "ORDERBY", "ORIGINALVALUE", "COUNT", "LENGTH", "CONTAINS", "STARTSWITH", 
		"ENDSWITH", "INDEXOF", "SUBSTRING", "TRIMSECONDS", "TRIMMILLISECONDS", 
		"FIRST", "TAKE", "SKIPQ", "ANY", "ALL", "TOLOWER", "TOUPPER", "ISDEFAULT", 
		"URLENCODE", "DATE", "DATETIME", "UTCDATETIME", "ADDDAYS", "SUM", "ASC", 
		"DESC", "WS", "PATH_PART", "DOT_SEPARATOR", "SLASH_SEPARATOR", "COMMA_SEPARATOR", 
		"PAREN_LEFT", "PAREN_RIGHT", "CURLY_BRACKET_LEFT", "CURLY_BRACKET_RIGHT", 
		"OPEN_SQUARE_BRACKET", "CLOSE_SQUARE_BRACKET", "QUOTED_STRING", "INT", 
		"FLOAT", "MACRO", "PARAM", "EscapeSequence", "DIGIT",
	];


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(this, ExpressionLexer._ATN, ExpressionLexer.DecisionsToDFA, new PredictionContextCache());
	}

	public get grammarFileName(): string { return "Expression.g4"; }

	public get literalNames(): (string | null)[] { return ExpressionLexer.literalNames; }
	public get symbolicNames(): (string | null)[] { return ExpressionLexer.symbolicNames; }
	public get ruleNames(): string[] { return ExpressionLexer.ruleNames; }

	public get serializedATN(): number[] { return ExpressionLexer._serializedATN; }

	public get channelNames(): string[] { return ExpressionLexer.channelNames; }

	public get modeNames(): string[] { return ExpressionLexer.modeNames; }

	public static readonly _serializedATN: number[] = [4,0,58,495,6,-1,2,0,
	7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,
	7,9,2,10,7,10,2,11,7,11,2,12,7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,
	16,2,17,7,17,2,18,7,18,2,19,7,19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,
	2,24,7,24,2,25,7,25,2,26,7,26,2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,
	31,7,31,2,32,7,32,2,33,7,33,2,34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,
	7,38,2,39,7,39,2,40,7,40,2,41,7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,
	45,2,46,7,46,2,47,7,47,2,48,7,48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,
	2,53,7,53,2,54,7,54,2,55,7,55,2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,1,
	0,1,0,1,0,1,0,1,0,1,1,1,1,1,1,1,1,1,1,1,2,1,2,1,2,1,2,1,2,1,2,1,3,1,3,1,
	3,1,3,1,3,1,4,1,4,1,4,1,5,1,5,1,5,1,6,1,6,1,7,1,7,1,7,1,8,1,8,1,8,1,9,1,
	9,1,10,1,10,1,11,1,11,1,11,1,12,1,12,1,12,1,13,1,13,1,13,1,13,1,13,1,13,
	1,13,1,14,1,14,1,14,1,14,1,14,1,14,1,15,1,15,1,15,1,15,1,15,1,15,1,15,1,
	15,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,1,16,
	1,17,1,17,1,17,1,17,1,17,1,17,1,18,1,18,1,18,1,18,1,18,1,18,1,18,1,19,1,
	19,1,19,1,19,1,19,1,19,1,19,1,19,1,19,1,20,1,20,1,20,1,20,1,20,1,20,1,20,
	1,20,1,20,1,20,1,20,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,21,1,22,1,
	22,1,22,1,22,1,22,1,22,1,22,1,22,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,23,
	1,23,1,23,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,24,1,
	25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,25,
	1,25,1,25,1,26,1,26,1,26,1,26,1,26,1,26,1,27,1,27,1,27,1,27,1,27,1,28,1,
	28,1,28,1,28,1,28,1,29,1,29,1,29,1,29,1,30,1,30,1,30,1,30,1,31,1,31,1,31,
	1,31,1,31,1,31,1,31,1,31,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,33,1,
	33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,34,1,34,1,34,1,34,1,34,1,34,
	1,34,1,34,1,34,1,34,1,35,1,35,1,35,1,35,1,35,1,36,1,36,1,36,1,36,1,36,1,
	36,1,36,1,36,1,36,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,37,1,37,
	1,37,1,38,1,38,1,38,1,38,1,38,1,38,1,38,1,38,1,39,1,39,1,39,1,39,1,40,1,
	40,1,40,1,40,1,40,1,40,1,40,1,40,1,40,3,40,398,8,40,1,41,1,41,1,41,1,41,
	1,41,1,41,1,41,1,41,1,41,1,41,1,41,1,41,3,41,412,8,41,1,42,4,42,415,8,42,
	11,42,12,42,416,1,42,1,42,1,43,1,43,5,43,423,8,43,10,43,12,43,426,9,43,
	1,44,1,44,1,45,1,45,1,46,1,46,1,47,1,47,1,48,1,48,1,49,1,49,1,49,1,50,1,
	50,1,50,1,51,1,51,1,51,1,52,1,52,1,52,1,53,1,53,1,53,5,53,453,8,53,10,53,
	12,53,456,9,53,1,53,1,53,1,54,4,54,461,8,54,11,54,12,54,462,1,55,4,55,466,
	8,55,11,55,12,55,467,1,55,1,55,5,55,472,8,55,10,55,12,55,475,9,55,1,56,
	1,56,1,57,1,57,5,57,481,8,57,10,57,12,57,484,9,57,1,58,1,58,1,58,1,58,1,
	58,1,58,1,58,1,58,1,59,1,59,0,0,60,1,1,3,2,5,3,7,4,9,5,11,6,13,7,15,8,17,
	9,19,10,21,11,23,12,25,13,27,14,29,15,31,16,33,17,35,18,37,19,39,20,41,
	21,43,22,45,23,47,24,49,25,51,26,53,27,55,28,57,29,59,30,61,31,63,32,65,
	33,67,34,69,35,71,36,73,37,75,38,77,39,79,40,81,41,83,42,85,43,87,44,89,
	45,91,46,93,47,95,48,97,49,99,50,101,51,103,52,105,53,107,54,109,55,111,
	56,113,57,115,58,117,0,119,0,1,0,6,3,0,9,10,13,13,32,32,2,0,65,90,97,122,
	4,0,48,57,65,90,95,95,97,122,2,0,34,34,92,92,1,0,64,64,2,0,45,45,48,57,
	504,0,1,1,0,0,0,0,3,1,0,0,0,0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,11,1,
	0,0,0,0,13,1,0,0,0,0,15,1,0,0,0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,
	0,23,1,0,0,0,0,25,1,0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,
	0,0,0,0,35,1,0,0,0,0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,0,0,
	0,45,1,0,0,0,0,47,1,0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,0,53,1,0,0,0,0,55,1,
	0,0,0,0,57,1,0,0,0,0,59,1,0,0,0,0,61,1,0,0,0,0,63,1,0,0,0,0,65,1,0,0,0,
	0,67,1,0,0,0,0,69,1,0,0,0,0,71,1,0,0,0,0,73,1,0,0,0,0,75,1,0,0,0,0,77,1,
	0,0,0,0,79,1,0,0,0,0,81,1,0,0,0,0,83,1,0,0,0,0,85,1,0,0,0,0,87,1,0,0,0,
	0,89,1,0,0,0,0,91,1,0,0,0,0,93,1,0,0,0,0,95,1,0,0,0,0,97,1,0,0,0,0,99,1,
	0,0,0,0,101,1,0,0,0,0,103,1,0,0,0,0,105,1,0,0,0,0,107,1,0,0,0,0,109,1,0,
	0,0,0,111,1,0,0,0,0,113,1,0,0,0,0,115,1,0,0,0,1,121,1,0,0,0,3,126,1,0,0,
	0,5,131,1,0,0,0,7,137,1,0,0,0,9,142,1,0,0,0,11,145,1,0,0,0,13,148,1,0,0,
	0,15,150,1,0,0,0,17,153,1,0,0,0,19,156,1,0,0,0,21,158,1,0,0,0,23,160,1,
	0,0,0,25,163,1,0,0,0,27,166,1,0,0,0,29,173,1,0,0,0,31,179,1,0,0,0,33,187,
	1,0,0,0,35,201,1,0,0,0,37,207,1,0,0,0,39,214,1,0,0,0,41,223,1,0,0,0,43,
	234,1,0,0,0,45,243,1,0,0,0,47,251,1,0,0,0,49,261,1,0,0,0,51,273,1,0,0,0,
	53,290,1,0,0,0,55,296,1,0,0,0,57,301,1,0,0,0,59,306,1,0,0,0,61,310,1,0,
	0,0,63,314,1,0,0,0,65,322,1,0,0,0,67,330,1,0,0,0,69,340,1,0,0,0,71,350,
	1,0,0,0,73,355,1,0,0,0,75,364,1,0,0,0,77,376,1,0,0,0,79,384,1,0,0,0,81,
	397,1,0,0,0,83,411,1,0,0,0,85,414,1,0,0,0,87,420,1,0,0,0,89,427,1,0,0,0,
	91,429,1,0,0,0,93,431,1,0,0,0,95,433,1,0,0,0,97,435,1,0,0,0,99,437,1,0,
	0,0,101,440,1,0,0,0,103,443,1,0,0,0,105,446,1,0,0,0,107,449,1,0,0,0,109,
	460,1,0,0,0,111,465,1,0,0,0,113,476,1,0,0,0,115,478,1,0,0,0,117,485,1,0,
	0,0,119,493,1,0,0,0,121,122,5,110,0,0,122,123,5,117,0,0,123,124,5,108,0,
	0,124,125,5,108,0,0,125,2,1,0,0,0,126,127,5,116,0,0,127,128,5,114,0,0,128,
	129,5,117,0,0,129,130,5,101,0,0,130,4,1,0,0,0,131,132,5,102,0,0,132,133,
	5,97,0,0,133,134,5,108,0,0,134,135,5,115,0,0,135,136,5,101,0,0,136,6,1,
	0,0,0,137,138,5,71,0,0,138,139,5,117,0,0,139,140,5,105,0,0,140,141,5,100,
	0,0,141,8,1,0,0,0,142,143,5,38,0,0,143,144,5,38,0,0,144,10,1,0,0,0,145,
	146,5,124,0,0,146,147,5,124,0,0,147,12,1,0,0,0,148,149,5,33,0,0,149,14,
	1,0,0,0,150,151,5,62,0,0,151,152,5,61,0,0,152,16,1,0,0,0,153,154,5,60,0,
	0,154,155,5,61,0,0,155,18,1,0,0,0,156,157,5,62,0,0,157,20,1,0,0,0,158,159,
	5,60,0,0,159,22,1,0,0,0,160,161,5,61,0,0,161,162,5,61,0,0,162,24,1,0,0,
	0,163,164,5,33,0,0,164,165,5,61,0,0,165,26,1,0,0,0,166,167,5,79,0,0,167,
	168,5,102,0,0,168,169,5,84,0,0,169,170,5,121,0,0,170,171,5,112,0,0,171,
	172,5,101,0,0,172,28,1,0,0,0,173,174,5,87,0,0,174,175,5,104,0,0,175,176,
	5,101,0,0,176,177,5,114,0,0,177,178,5,101,0,0,178,30,1,0,0,0,179,180,5,
	79,0,0,180,181,5,114,0,0,181,182,5,100,0,0,182,183,5,101,0,0,183,184,5,
	114,0,0,184,185,5,66,0,0,185,186,5,121,0,0,186,32,1,0,0,0,187,188,5,79,
	0,0,188,189,5,114,0,0,189,190,5,105,0,0,190,191,5,103,0,0,191,192,5,105,
	0,0,192,193,5,110,0,0,193,194,5,97,0,0,194,195,5,108,0,0,195,196,5,86,0,
	0,196,197,5,97,0,0,197,198,5,108,0,0,198,199,5,117,0,0,199,200,5,101,0,
	0,200,34,1,0,0,0,201,202,5,67,0,0,202,203,5,111,0,0,203,204,5,117,0,0,204,
	205,5,110,0,0,205,206,5,116,0,0,206,36,1,0,0,0,207,208,5,76,0,0,208,209,
	5,101,0,0,209,210,5,110,0,0,210,211,5,103,0,0,211,212,5,116,0,0,212,213,
	5,104,0,0,213,38,1,0,0,0,214,215,5,67,0,0,215,216,5,111,0,0,216,217,5,110,
	0,0,217,218,5,116,0,0,218,219,5,97,0,0,219,220,5,105,0,0,220,221,5,110,
	0,0,221,222,5,115,0,0,222,40,1,0,0,0,223,224,5,83,0,0,224,225,5,116,0,0,
	225,226,5,97,0,0,226,227,5,114,0,0,227,228,5,116,0,0,228,229,5,115,0,0,
	229,230,5,87,0,0,230,231,5,105,0,0,231,232,5,116,0,0,232,233,5,104,0,0,
	233,42,1,0,0,0,234,235,5,69,0,0,235,236,5,110,0,0,236,237,5,100,0,0,237,
	238,5,115,0,0,238,239,5,87,0,0,239,240,5,105,0,0,240,241,5,116,0,0,241,
	242,5,104,0,0,242,44,1,0,0,0,243,244,5,73,0,0,244,245,5,110,0,0,245,246,
	5,100,0,0,246,247,5,101,0,0,247,248,5,120,0,0,248,249,5,79,0,0,249,250,
	5,102,0,0,250,46,1,0,0,0,251,252,5,83,0,0,252,253,5,117,0,0,253,254,5,98,
	0,0,254,255,5,115,0,0,255,256,5,116,0,0,256,257,5,114,0,0,257,258,5,105,
	0,0,258,259,5,110,0,0,259,260,5,103,0,0,260,48,1,0,0,0,261,262,5,84,0,0,
	262,263,5,114,0,0,263,264,5,105,0,0,264,265,5,109,0,0,265,266,5,83,0,0,
	266,267,5,101,0,0,267,268,5,99,0,0,268,269,5,111,0,0,269,270,5,110,0,0,
	270,271,5,100,0,0,271,272,5,115,0,0,272,50,1,0,0,0,273,274,5,84,0,0,274,
	275,5,114,0,0,275,276,5,105,0,0,276,277,5,109,0,0,277,278,5,77,0,0,278,
	279,5,105,0,0,279,280,5,108,0,0,280,281,5,108,0,0,281,282,5,105,0,0,282,
	283,5,115,0,0,283,284,5,101,0,0,284,285,5,99,0,0,285,286,5,111,0,0,286,
	287,5,110,0,0,287,288,5,100,0,0,288,289,5,115,0,0,289,52,1,0,0,0,290,291,
	5,70,0,0,291,292,5,105,0,0,292,293,5,114,0,0,293,294,5,115,0,0,294,295,
	5,116,0,0,295,54,1,0,0,0,296,297,5,84,0,0,297,298,5,97,0,0,298,299,5,107,
	0,0,299,300,5,101,0,0,300,56,1,0,0,0,301,302,5,83,0,0,302,303,5,107,0,0,
	303,304,5,105,0,0,304,305,5,112,0,0,305,58,1,0,0,0,306,307,5,65,0,0,307,
	308,5,110,0,0,308,309,5,121,0,0,309,60,1,0,0,0,310,311,5,65,0,0,311,312,
	5,108,0,0,312,313,5,108,0,0,313,62,1,0,0,0,314,315,5,84,0,0,315,316,5,111,
	0,0,316,317,5,76,0,0,317,318,5,111,0,0,318,319,5,119,0,0,319,320,5,101,
	0,0,320,321,5,114,0,0,321,64,1,0,0,0,322,323,5,84,0,0,323,324,5,111,0,0,
	324,325,5,85,0,0,325,326,5,112,0,0,326,327,5,112,0,0,327,328,5,101,0,0,
	328,329,5,114,0,0,329,66,1,0,0,0,330,331,5,73,0,0,331,332,5,115,0,0,332,
	333,5,68,0,0,333,334,5,101,0,0,334,335,5,102,0,0,335,336,5,97,0,0,336,337,
	5,117,0,0,337,338,5,108,0,0,338,339,5,116,0,0,339,68,1,0,0,0,340,341,5,
	85,0,0,341,342,5,114,0,0,342,343,5,108,0,0,343,344,5,69,0,0,344,345,5,110,
	0,0,345,346,5,99,0,0,346,347,5,111,0,0,347,348,5,100,0,0,348,349,5,101,
	0,0,349,70,1,0,0,0,350,351,5,68,0,0,351,352,5,97,0,0,352,353,5,116,0,0,
	353,354,5,101,0,0,354,72,1,0,0,0,355,356,5,68,0,0,356,357,5,97,0,0,357,
	358,5,116,0,0,358,359,5,101,0,0,359,360,5,84,0,0,360,361,5,105,0,0,361,
	362,5,109,0,0,362,363,5,101,0,0,363,74,1,0,0,0,364,365,5,85,0,0,365,366,
	5,116,0,0,366,367,5,99,0,0,367,368,5,68,0,0,368,369,5,97,0,0,369,370,5,
	116,0,0,370,371,5,101,0,0,371,372,5,84,0,0,372,373,5,105,0,0,373,374,5,
	109,0,0,374,375,5,101,0,0,375,76,1,0,0,0,376,377,5,65,0,0,377,378,5,100,
	0,0,378,379,5,100,0,0,379,380,5,68,0,0,380,381,5,97,0,0,381,382,5,121,0,
	0,382,383,5,115,0,0,383,78,1,0,0,0,384,385,5,83,0,0,385,386,5,117,0,0,386,
	387,5,109,0,0,387,80,1,0,0,0,388,389,5,97,0,0,389,390,5,115,0,0,390,398,
	5,99,0,0,391,392,5,65,0,0,392,393,5,115,0,0,393,398,5,99,0,0,394,395,5,
	65,0,0,395,396,5,83,0,0,396,398,5,67,0,0,397,388,1,0,0,0,397,391,1,0,0,
	0,397,394,1,0,0,0,398,82,1,0,0,0,399,400,5,100,0,0,400,401,5,101,0,0,401,
	402,5,115,0,0,402,412,5,99,0,0,403,404,5,68,0,0,404,405,5,101,0,0,405,406,
	5,115,0,0,406,412,5,99,0,0,407,408,5,68,0,0,408,409,5,69,0,0,409,410,5,
	83,0,0,410,412,5,67,0,0,411,399,1,0,0,0,411,403,1,0,0,0,411,407,1,0,0,0,
	412,84,1,0,0,0,413,415,7,0,0,0,414,413,1,0,0,0,415,416,1,0,0,0,416,414,
	1,0,0,0,416,417,1,0,0,0,417,418,1,0,0,0,418,419,6,42,0,0,419,86,1,0,0,0,
	420,424,7,1,0,0,421,423,7,2,0,0,422,421,1,0,0,0,423,426,1,0,0,0,424,422,
	1,0,0,0,424,425,1,0,0,0,425,88,1,0,0,0,426,424,1,0,0,0,427,428,5,46,0,0,
	428,90,1,0,0,0,429,430,5,47,0,0,430,92,1,0,0,0,431,432,5,44,0,0,432,94,
	1,0,0,0,433,434,5,40,0,0,434,96,1,0,0,0,435,436,5,41,0,0,436,98,1,0,0,0,
	437,438,5,123,0,0,438,439,5,60,0,0,439,100,1,0,0,0,440,441,5,62,0,0,441,
	442,5,125,0,0,442,102,1,0,0,0,443,444,5,91,0,0,444,445,5,91,0,0,445,104,
	1,0,0,0,446,447,5,93,0,0,447,448,5,93,0,0,448,106,1,0,0,0,449,454,5,34,
	0,0,450,453,3,117,58,0,451,453,8,3,0,0,452,450,1,0,0,0,452,451,1,0,0,0,
	453,456,1,0,0,0,454,452,1,0,0,0,454,455,1,0,0,0,455,457,1,0,0,0,456,454,
	1,0,0,0,457,458,5,34,0,0,458,108,1,0,0,0,459,461,3,119,59,0,460,459,1,0,
	0,0,461,462,1,0,0,0,462,460,1,0,0,0,462,463,1,0,0,0,463,110,1,0,0,0,464,
	466,3,119,59,0,465,464,1,0,0,0,466,467,1,0,0,0,467,465,1,0,0,0,467,468,
	1,0,0,0,468,469,1,0,0,0,469,473,5,46,0,0,470,472,3,119,59,0,471,470,1,0,
	0,0,472,475,1,0,0,0,473,471,1,0,0,0,473,474,1,0,0,0,474,112,1,0,0,0,475,
	473,1,0,0,0,476,477,5,37,0,0,477,114,1,0,0,0,478,482,7,4,0,0,479,481,7,
	2,0,0,480,479,1,0,0,0,481,484,1,0,0,0,482,480,1,0,0,0,482,483,1,0,0,0,483,
	116,1,0,0,0,484,482,1,0,0,0,485,486,5,47,0,0,486,487,9,0,0,0,487,488,5,
	38,0,0,488,489,5,38,0,0,489,490,1,0,0,0,490,491,5,124,0,0,491,492,5,124,
	0,0,492,118,1,0,0,0,493,494,7,5,0,0,494,120,1,0,0,0,11,0,397,411,416,424,
	452,454,462,467,473,482,1,6,0,0];

	private static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!ExpressionLexer.__ATN) {
			ExpressionLexer.__ATN = new ATNDeserializer().deserialize(ExpressionLexer._serializedATN);
		}

		return ExpressionLexer.__ATN;
	}


	static DecisionsToDFA = ExpressionLexer._ATN.decisionToState.map( (ds: DecisionState, index: number) => new DFA(ds, index) );
}