// Generated from Expression.g4 by ANTLR 4.13.1

import {ParseTreeVisitor} from 'antlr4';


import { DependencyContext } from "./ExpressionParser";
import { Dependency_pathContext } from "./ExpressionParser";
import { Dependency_exprContext } from "./ExpressionParser";
import { PathContext } from "./ExpressionParser";
import { Embedded_pathContext } from "./ExpressionParser";
import { Path_partContext } from "./ExpressionParser";
import { Func_sequenceContext } from "./ExpressionParser";
import { FuncContext } from "./ExpressionParser";
import { Bool_result_funcContext } from "./ExpressionParser";
import { WhereContext } from "./ExpressionParser";
import { AnyContext } from "./ExpressionParser";
import { AllContext } from "./ExpressionParser";
import { OrderbyContext } from "./ExpressionParser";
import { Orderby_coreContext } from "./ExpressionParser";
import { OftypeContext } from "./ExpressionParser";
import { OriginalvalueContext } from "./ExpressionParser";
import { CountContext } from "./ExpressionParser";
import { LengthContext } from "./ExpressionParser";
import { ContainsContext } from "./ExpressionParser";
import { StartswithContext } from "./ExpressionParser";
import { EndswithContext } from "./ExpressionParser";
import { IndexofContext } from "./ExpressionParser";
import { SubstringContext } from "./ExpressionParser";
import { TrimsecondsContext } from "./ExpressionParser";
import { TrimmillisecondsContext } from "./ExpressionParser";
import { FirstContext } from "./ExpressionParser";
import { TakeContext } from "./ExpressionParser";
import { SkipQContext } from "./ExpressionParser";
import { TolowerContext } from "./ExpressionParser";
import { ToupperContext } from "./ExpressionParser";
import { IsdefaultContext } from "./ExpressionParser";
import { UrlencodeContext } from "./ExpressionParser";
import { DateContext } from "./ExpressionParser";
import { DatetimeContext } from "./ExpressionParser";
import { UtcdatetimeContext } from "./ExpressionParser";
import { AdddaysContext } from "./ExpressionParser";
import { SumContext } from "./ExpressionParser";
import { Int_func_argumentContext } from "./ExpressionParser";
import { String_func_argumentContext } from "./ExpressionParser";
import { Type_nameContext } from "./ExpressionParser";
import { ExprContext } from "./ExpressionParser";
import { GuidContext } from "./ExpressionParser";
import { Expr_partContext } from "./ExpressionParser";
import { Expr_part_no_operatorContext } from "./ExpressionParser";
import { GreaterOrEqualOperatorContext } from "./ExpressionParser";
import { LessOrEqualOperatorContext } from "./ExpressionParser";
import { GreaterOperatorContext } from "./ExpressionParser";
import { LessOperatorContext } from "./ExpressionParser";
import { EqualsOperatorContext } from "./ExpressionParser";
import { NotEqualsOperatorContext } from "./ExpressionParser";
import { SeparatorContext } from "./ExpressionParser";
import { DotSeparatorContext } from "./ExpressionParser";
import { SlashSeparatorContext } from "./ExpressionParser";
import { NotContext } from "./ExpressionParser";
import { Boolean_operatorContext } from "./ExpressionParser";


/**
 * This interface defines a complete generic visitor for a parse tree produced
 * by `ExpressionParser`.
 *
 * @param <Result> The return type of the visit operation. Use `void` for
 * operations with no return type.
 */
export default class ExpressionVisitor<Result> extends ParseTreeVisitor<Result> {
	/**
	 * Visit a parse tree produced by `ExpressionParser.dependency`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitDependency?: (ctx: DependencyContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.dependency_path`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitDependency_path?: (ctx: Dependency_pathContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.dependency_expr`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitDependency_expr?: (ctx: Dependency_exprContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.path`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitPath?: (ctx: PathContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.embedded_path`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitEmbedded_path?: (ctx: Embedded_pathContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.path_part`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitPath_part?: (ctx: Path_partContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.func_sequence`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitFunc_sequence?: (ctx: Func_sequenceContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.func`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitFunc?: (ctx: FuncContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.bool_result_func`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitBool_result_func?: (ctx: Bool_result_funcContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.where`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitWhere?: (ctx: WhereContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.any`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitAny?: (ctx: AnyContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.all`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitAll?: (ctx: AllContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.orderby`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitOrderby?: (ctx: OrderbyContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.orderby_core`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitOrderby_core?: (ctx: Orderby_coreContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.oftype`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitOftype?: (ctx: OftypeContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.originalvalue`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitOriginalvalue?: (ctx: OriginalvalueContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.count`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitCount?: (ctx: CountContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.length`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitLength?: (ctx: LengthContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.contains`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitContains?: (ctx: ContainsContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.startswith`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitStartswith?: (ctx: StartswithContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.endswith`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitEndswith?: (ctx: EndswithContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.indexof`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitIndexof?: (ctx: IndexofContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.substring`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitSubstring?: (ctx: SubstringContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.trimseconds`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitTrimseconds?: (ctx: TrimsecondsContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.trimmilliseconds`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitTrimmilliseconds?: (ctx: TrimmillisecondsContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.first`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitFirst?: (ctx: FirstContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.take`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitTake?: (ctx: TakeContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.skipQ`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitSkipQ?: (ctx: SkipQContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.tolower`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitTolower?: (ctx: TolowerContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.toupper`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitToupper?: (ctx: ToupperContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.isdefault`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitIsdefault?: (ctx: IsdefaultContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.urlencode`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitUrlencode?: (ctx: UrlencodeContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.date`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitDate?: (ctx: DateContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.datetime`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitDatetime?: (ctx: DatetimeContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.utcdatetime`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitUtcdatetime?: (ctx: UtcdatetimeContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.adddays`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitAdddays?: (ctx: AdddaysContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.sum`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitSum?: (ctx: SumContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.int_func_argument`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitInt_func_argument?: (ctx: Int_func_argumentContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.string_func_argument`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitString_func_argument?: (ctx: String_func_argumentContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.type_name`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitType_name?: (ctx: Type_nameContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.expr`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitExpr?: (ctx: ExprContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.guid`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitGuid?: (ctx: GuidContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.expr_part`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitExpr_part?: (ctx: Expr_partContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.expr_part_no_operator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitExpr_part_no_operator?: (ctx: Expr_part_no_operatorContext) => Result;
	/**
	 * Visit a parse tree produced by the `greaterOrEqualOperator`
	 * labeled alternative in `ExpressionParser.operator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitGreaterOrEqualOperator?: (ctx: GreaterOrEqualOperatorContext) => Result;
	/**
	 * Visit a parse tree produced by the `lessOrEqualOperator`
	 * labeled alternative in `ExpressionParser.operator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitLessOrEqualOperator?: (ctx: LessOrEqualOperatorContext) => Result;
	/**
	 * Visit a parse tree produced by the `greaterOperator`
	 * labeled alternative in `ExpressionParser.operator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitGreaterOperator?: (ctx: GreaterOperatorContext) => Result;
	/**
	 * Visit a parse tree produced by the `lessOperator`
	 * labeled alternative in `ExpressionParser.operator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitLessOperator?: (ctx: LessOperatorContext) => Result;
	/**
	 * Visit a parse tree produced by the `equalsOperator`
	 * labeled alternative in `ExpressionParser.operator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitEqualsOperator?: (ctx: EqualsOperatorContext) => Result;
	/**
	 * Visit a parse tree produced by the `notEqualsOperator`
	 * labeled alternative in `ExpressionParser.operator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitNotEqualsOperator?: (ctx: NotEqualsOperatorContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.separator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitSeparator?: (ctx: SeparatorContext) => Result;
	/**
	 * Visit a parse tree produced by the `dotSeparator`
	 * labeled alternative in `ExpressionParser.dot_separator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitDotSeparator?: (ctx: DotSeparatorContext) => Result;
	/**
	 * Visit a parse tree produced by the `slashSeparator`
	 * labeled alternative in `ExpressionParser.slash_separator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitSlashSeparator?: (ctx: SlashSeparatorContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.not`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitNot?: (ctx: NotContext) => Result;
	/**
	 * Visit a parse tree produced by `ExpressionParser.boolean_operator`.
	 * @param ctx the parse tree
	 * @return the visitor result
	 */
	visitBoolean_operator?: (ctx: Boolean_operatorContext) => Result;
}

