import {
  CharStream,
  CommonTokenStream,
  type ErrorListener,
  type Token,
  type ParserRuleContext,
} from "antlr4";
import ExpressionLexer from "./ExpressionLexer";
import ExpressionParser from "./ExpressionParser";
import ExpressionVisitorBase from "./ExpressionVisitor";

const treeCache = new Map<string, ParserRuleContext>();
const treeCacheMaxSize = 2000;

enum TreeName {
  Dependency = "dependency",
  Path = "path",
}

class ExpressionVisitor<Result> extends ExpressionVisitorBase<Result> {
  static TreeName = TreeName;

  static getDependencyTree(
    path: string,
    treeName: TreeName,
    errorCallback?: (msg: string) => void
  ): ParserRuleContext {
    const treeKey = path + treeName;
    let tree = treeCache.get(treeKey);
    if (!tree) {
      tree = parseTree(path, treeName, errorCallback);
      if (treeCache.size >= treeCacheMaxSize) {
        clearCache();
      }
      treeCache.set(treeKey, tree);
    }

    return tree;
  }

  static isValid(path: string, treeName: TreeName): boolean {
    let isValid = true;
    parseTree(path, treeName, () => {
      isValid = false;
    });

    return isValid;
  }
}

function clearCache(): void {
  treeCache.clear();
}

function parseTree(
  path: string,
  treeName: TreeName,
  errorCallback?: (msg: string) => void
): ParserRuleContext {
  const chars = new CharStream(path);
  const lexer = new ExpressionLexer(chars);
  const tokens = new CommonTokenStream(lexer);
  const parser = new ExpressionParser(tokens);

  if (errorCallback) {
    const proxyListener = (parser as unknown as RecognizerInternals).getErrorListenerDispatch();
    proxyListener.delegates[0].syntaxError = (
      _recognizer,
      _offendingSymbol,
      _line,
      _column,
      msg
    ): void => {
      errorCallback(msg);
    };
  }

  parser.buildParseTrees = true;
  return parser[treeName]();
}

interface ProxyErrorListener {
  delegates: ErrorListener<Token>[];
}

interface RecognizerInternals {
  getErrorListenerDispatch(): ProxyErrorListener;
}

export { ExpressionParser, ExpressionVisitor };
